import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const getEmailTemplateForSoccorso = (content = '', fallback = '') => {
    const config = {
        FORBID_TAGS: ['html', 'body'],
        WHOLE_DOCUMENT: false,
        RETURN_DOM: false,
        RETURN_DOM_FRAGMENT: false,
        RETURN_DOM_IMPORT: false,
        FORCE_BODY: false,
        ADD_TAGS: ['*'],
        ADD_ATTR: ['*']
    };
    try {
        const wrappedHtml = `<div>${content}</div>`;
        const cleaned = DOMPurify.sanitize(wrappedHtml, config);

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = cleaned;
        return parse(tempDiv.innerHTML);
    } catch (error) {
        console.error('DOMPurify cleaning error:', error);
        return fallback;
    }
}

export default getEmailTemplateForSoccorso;