import React, { useCallback, useEffect, useState } from 'react';
import { __, sprintf } from '@wordpress/i18n';
import { wrap } from 'object-path-immutable';
import { isEmpty, last, pathOr } from 'ramda';

// components
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';

// tools
import uniqid from '../../../../../../helpers/uniqid';
import removeKey from '../../../../../../helpers/removeKey';

const ElementSettingCriteriaTableColumns = ({
                                        value,
                                        name,
                                        setDataFn,
                                        bandoStatus
                                    }) => {
    const [stateFieldData, setStateFieldData] = useState([]);
    const [rowsData, setRowsData] = useState([]);

    const removeItem = (index) => {
        let newData = stateFieldData
            .toSpliced(index, 1);
        newData = newData.map((o, i) => {
                return i === newData.length - 1
                    ? {...o, fieldtype: 'numeric', predefined: false, enableFormula: true}
                    : {...o, fieldtype: 'text', predefined: true, enableFormula: false}
            });
        setStateFieldData(newData);
        const newRowsData = removeKey(rowsData, last(newData.map(o => o.name)));
        setRowsData(newRowsData);
    }

    const addNewItem = () => {
        setStateFieldData([
            ...stateFieldData.map(o => ({...o, fieldtype: 'text', predefined: true, enableFormula: false})),
            { name: uniqid('o'), label: '', fieldtype: 'numeric', predefined: false, enableFormula: true }
        ]);
    }

    const addNewRow = () => {
        const obj = stateFieldData
            .filter(o => o.predefined)
            .reduce((acc, cur) => {
                acc[cur.name] = ''
                return acc;
            }, {});
        setRowsData([...rowsData, obj]);
    }

    const removeRow = (index) => {
        const newRowsData = wrap(rowsData).del([index]).value();
        setRowsData(newRowsData);
    }

    const onInputChange = (e, index) => {
        const { value } = e.target;
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                o.label = value;
            }
            return o;
        })
        setStateFieldData(newData);
    }

    const onLastRowInputChange = (e, index) => {
        const { value } = e.target;
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                o.lastRowText = value;
            }
            return o;
        })
        setStateFieldData(newData);
    }

    const onTypeChange = (value, index) => {
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                o.fieldtype = value;
            }
            return o;
        })
        setStateFieldData(newData);
    }

    const onLastRowFormulaChange = (value, index) => {
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                o.lastRowFormula = value;
            }
            return o;
        })
        setStateFieldData(newData);
    }

    const onSubInputChange = (e, name, index) => {
        const { value } = e.target;
        const newRowsData = wrap(rowsData).set([index, name], value).value();
        setRowsData(newRowsData);
    }

    const setChecked = (value, index) => {
        let name = '';
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                o.predefined = value;
                name = o.name;
            }
            return o;
        });

        let newRowsData = [];

        if (value === false) {
            newRowsData = rowsData.map(o => wrap(o).set([name], '').value());
        } else {
            newRowsData = rowsData.map(o => wrap(o).set([name], '').value());
        }

        setRowsData(newRowsData);
        setStateFieldData(newData);
    }

    const setColFormulaChecked = (index) => {
        const newData = stateFieldData.map((o, i) => {
            if (i === index) {
                const newVal = o.enableFormula !== true;
                o.enableFormula = newVal;
                if (newVal) {
                    o.lastRowFormula = 'sum';
                    o.fieldtype = 'numeric';
                    delete o.lastRowText;
                } else {
                    delete o.lastRowFormula;
                    delete o.fieldtype;
                    o.lastRowText = '';
                }
            }
            return o;
        });
        setStateFieldData(newData);
    }

    const handleClearLastRowData = useCallback(() => {
        const newData = stateFieldData.map((o) => {
            delete o.lastRowFormula;
            o.lastRowText = '';
            delete o.enableFormula;

            return o;
        });

        setStateFieldData(newData);
    }, [stateFieldData]);

    const properFields = (item, i) => {
        return <>
            <div>
                <InputText
                    value={item.label}
                    placeholder={sprintf(__('Colonna %d', 'gepafin'), i + 1)}
                    onInput={(e) => onInputChange(e, i)}/>
            </div>
            <div>
                <Dropdown
                    disabled={true}
                    value={item.fieldtype ? item.fieldtype : 'text'}
                    onChange={(e) => onTypeChange(e.value, i)}
                    options={[
                        { value: 'text', label: __('Testo', 'gepafin') },
                        { value: 'numeric', label: __('Numerico', 'gepafin') }
                    ]}/>
            </div>
            <div>
                <button
                    disabled={true}
                    className="formElementSettings__repeaterItemIconBtn"
                    onClick={() => setColFormulaChecked(i)}
                    data-active={item.enableFormula ? item.enableFormula : false}
                    type="button">
                    <i className="pi pi-calculator"></i>
                </button>
            </div>
            <div>
                <InputSwitch
                    checked={item.predefined}
                    disabled={true}
                    onChange={(e) => setChecked(e.value, i)}/>
            </div>
            <div>
                <Button icon="pi pi-times"
                        disabled={bandoStatus === 'PUBLISH'}
                        className="p-button-danger"
                        onClick={() => removeItem(i)}/>
            </div>
        </>
    }

    const properSubField = (item, i, name) => {
        return <>
            <div>
                <InputText
                    value={item[name]}
                    onInput={(e) => onSubInputChange(e, name, i)}/>
            </div>
            <div>
                <Button icon="pi pi-times"
                        className="p-button-danger"
                        onClick={() => removeRow(i)}/>
            </div>
        </>
    }

    const properFieldsLastRow = useCallback((item, i) => {
        return <>
            <div>
                <span>{sprintf(__('Colonna %d'), i + 1)}</span>
            </div>
            {item.enableFormula
                ? <div>
                    <Dropdown
                        disabled={bandoStatus === 'PUBLISH'}
                        value={item.lastRowFormula}
                        onChange={(e) => onLastRowFormulaChange(e.value, i)}
                        options={[
                            { value: 'sum', label: __('Somma automatica', 'gepafin') }
                        ]}/>
                </div>
                : <div>
                    <InputText
                        value={item.lastRowText ? item.lastRowText : ''}
                        onInput={(e) => onLastRowInputChange(e, i)}/>
                </div>}
        </>
    }, [stateFieldData]);

    const lastRow = <div className="formElementSettings__repeater">
        <div className="formElementSettings__lastRowHeader">
            <div className="formElementSettings__lastRowHeaderTitle">
                {__('Definisci ultima righa', 'gepafin')}
            </div>
            <Button type="button"
                    disabled={bandoStatus === 'PUBLISH'}
                    outlined
                    label={__('Pulisci', 'gepafin')}
                    iconPos="right"
                    icon="pi pi-refresh"
                    onClick={handleClearLastRowData}/>
        </div>
        {stateFieldData.map((o, i) => <div key={i} className="formElementSettings__lastRowItem">
            {properFieldsLastRow(o, i)}
        </div>)}
    </div>;

    useEffect(() => {
        const stateFieldData = pathOr([], ['stateFieldData'], value);
        setStateFieldData(stateFieldData);
        const rowsData = pathOr([], ['rowsData'], value);
        setRowsData(rowsData);
    }, []);

    useEffect(() => {
        setDataFn(name, {
            stateFieldData,
            rowsData
        });
    }, [stateFieldData, rowsData]);

    return (
        <>
            <div className="formElementSettings__repeater">
                {stateFieldData.length > 0
                    ? <div className="formElementSettings__repeaterItem tableRow">
                        <div>{__('Colonne', 'gepafin')}</div>
                        <div>{__('Tipo', 'gepafin')}</div>
                        <div>{__('Calcola', 'gepafin')}</div>
                        <div>{__('Predefinito?', 'gepafin')}</div>
                        <div></div>
                    </div> : null}
                {stateFieldData.map((o, i) => <div key={i} className="formElementSettings__repeaterItem tableRow">
                    {properFields(o, i)}
                </div>)}
                <Button
                    type="button"
                    disabled={bandoStatus === 'PUBLISH'}
                    outlined
                    label={__('Aggiungi colonna', 'gepafin')}
                    onClick={addNewItem}/>
            </div>
            {stateFieldData
                .filter(o => o.predefined).length > 0
                ? <div className="formElementSettings__subRepeaterWrapper">
                    <Accordion activeIndex={0}>
                        {stateFieldData
                            //.filter(o => o.predefined)
                            .map((o, i) =>
                                o.predefined
                                    ? <AccordionTab
                                        key={i}
                                        header={sprintf(__('Righe per colonna: %s'), !isEmpty(o.label) ? o.label : i + 1)}>
                                        <div className="formElementSettings__subRepeaterWrapper">
                                            {rowsData.map((c, k) => {
                                                return <div key={k} className="formElementSettings__subRepeaterItem">
                                                    {properSubField(c, k, o.name)}
                                                </div>
                                            })}
                                            <Button type="button"
                                                    outlined
                                                    disabled={bandoStatus === 'PUBLISH'}
                                                    label={__('Aggiungi una riga', 'gepafin')}
                                                    onClick={addNewRow}/>
                                        </div>
                                    </AccordionTab> : null)}
                    </Accordion>
                </div>
                : null}
            {lastRow}
        </>
    )
}

export default ElementSettingCriteriaTableColumns;