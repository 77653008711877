import React, { useEffect, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate } from 'react-router-dom';
import NumberFlow from '@number-flow/react';
import { pathOr } from 'ramda';

// store
import { useStore } from '../../store';

// service
import DashboardService from '../../service/dashboard-service';

// components
import { Button } from 'primereact/button';
//import DomandeTablePreInstructorAsync from './components/DomandeTablePreInstructorAsync';
import PreInstructorDomandeTable from './components/PreInstructorDomandeTable';

const DashboardPreInstructor = () => {
    const navigate = useNavigate();
    const [mainStats, setMainStats] = useState({});
    const userData = useStore().main.userData();

    const goToAllEvaluations = () => {
        navigate('/domande');
    }

    const getStats = (data) => {
        if (data.status === 'SUCCESS') {
            setMainStats(data.data);
        }
    }

    const errGetStats = () => {}

    const getStatValue = (keys = [], fallback = '') => {
        return pathOr(fallback, keys, mainStats);
    }

    useEffect(() => {
        DashboardService.getInstructorAmendmentsStats(getStats, errGetStats, [
            ['userId', userData.id]
        ]);
    }, []);

    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Dashboard', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection statsBigBadges">
                <h2>{__('Riepilogo', 'gepafin')}</h2>
                <div className="statsBigBadges__grid doubleStatsItems">
                    <div className="statsBigBadges__gridItemDoubleStats">
                        <span>{__('Domande da valutare', 'gepafin')}</span>
                        <span className="number"><NumberFlow
                            value={getStatValue(['assignedApplication', 'totalAssignedApplication'], 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                        {/*<div className="auxStats">
                            <span>
                                <span
                                className="badge">{getStatValue(['assignedApplication', 'additionalApplicationPercentage'], 0)}%</span>
                                {__('da ieri', 'gepafin')}</span>
                        </div>*/}
                    </div>
                    <div className="statsBigBadges__gridItemDoubleStats">
                        <span>{__('Domande valutate', 'gepafin')}</span>
                        <span className="number"><NumberFlow
                            value={getStatValue(['evaluatedApplication', 'evaluatedApplication'], 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                        {/*<div className="auxStats">
                            <span>
                                <span
                                className="badge">{getStatValue(['evaluatedApplication', 'dailyAverage'], 0)}</span>
                                {__('media giornaliera', 'gepafin')}</span>
                        </div>*/}
                    </div>
                    <div className="statsBigBadges__gridItemDoubleStats">
                        <span>{__('Tempo medio valutazione', 'gepafin')}</span>
                        <span className="number"><NumberFlow
                            value={getStatValue(['averageEvaluationDays', 'averageEvlauationDaysRating'], 0)}
                            format={{ notation: 'compact' }}
                            suffix={` ${__('giorni', 'gepafin')}`}
                            locales="it-IT"/></span>
                        {/*<div className="auxStats">
                            <span>
                                <span className="badge">{getStatValue(['averageEvaluationDays', 'timeDifferenceFromAverage'], 0)}</span>
                                {__('rispetto alla media', 'gepafin')}</span>
                        </div>*/}
                    </div>
                    <div className="statsBigBadges__gridItemDoubleStats">
                        <span>{__('Soccorsi istruttori in corso', 'gepafin')}</span>
                        <span className="number"><NumberFlow
                            value={getStatValue(['amendmentInProgress', 'totalAmendmentInProgress'], 0)}
                            format={{ notation: 'compact' }}
                            locales="it-IT"/></span>
                        {/*<div className="auxStats">
                            <span>
                                <span className="badge">{getStatValue(['amendmentInProgress', 'expiringToday'], 0)}</span>
                                {__('in scadenza oggi', 'gepafin')}</span>
                        </div>*/}
                    </div>
                </div>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Coda di lavoro', 'gepafin')}</h2>
                <PreInstructorDomandeTable statuses={['OPEN', 'SOCCORSO']} userId={userData.id}/>
                {/*<DomandeTablePreInstructorAsync statuses={['OPEN', 'SOCCORSO']} userId={userData.id}/>*/}
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni rapide', 'gepafin')}</span>
            </div>

            <div className="appPageSection">
                <div className="appPageSection__actions">
                    <Button
                        onClick={goToAllEvaluations}
                        label={__('Tutte le domande', 'gepafin')} icon="pi pi-arrow-right" iconPos="right"/>
                </div>
            </div>
        </div>
    )
}

export default DashboardPreInstructor;