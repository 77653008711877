import React from 'react';
import { __ } from '@wordpress/i18n';

const PaginatorBasic = ({
                            currentPage = 0,
                            totalPages = 0,
                            clickFn = () => {
                            }
                        }) => {
    const handleClick = (num) => {
        const newNum = num < 0
            ? 0
            : num > totalPages ? totalPages : num;
        clickFn(newNum);
    }
    const prevDisabled = currentPage <= 1;
    const nextDisabled = currentPage >= totalPages

    return (
        totalPages !== 0
            ? <div className="p-paginator p-component" data-pc-name="paginator" data-pc-section="root">
                <button
                    type="button"
                    className={`p-paginator-prev p-paginator-element p-link${prevDisabled ? ' p-disabled' : ''}`}
                    disabled={prevDisabled}
                    onClick={prevDisabled ? () => {
                    } : () => handleClick(currentPage - 1)}
                    aria-label={__('Pagina precedente', 'gepafin')}
                    data-pc-section="prevpagebutton">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                         className="p-icon p-paginator-icon" aria-hidden="true" data-pc-section="prevpageicon">
                        <path
                            d="M8.75 11.185C8.65146 11.1854 8.55381 11.1662 8.4628 11.1284C8.37179 11.0906 8.28924 11.0351 8.22 10.965L4.72 7.46496C4.57955 7.32433 4.50066 7.13371 4.50066 6.93496C4.50066 6.73621 4.57955 6.54558 4.72 6.40496L8.22 2.93496C8.36095 2.84357 8.52851 2.80215 8.69582 2.81733C8.86312 2.83252 9.02048 2.90344 9.14268 3.01872C9.26487 3.134 9.34483 3.28696 9.36973 3.4531C9.39463 3.61924 9.36303 3.78892 9.28 3.93496L6.28 6.93496L9.28 9.93496C9.42045 10.0756 9.49934 10.2662 9.49934 10.465C9.49934 10.6637 9.42045 10.8543 9.28 10.995C9.13526 11.1257 8.9448 11.1939 8.75 11.185Z"
                            fill="currentColor"></path>
                    </svg>
                </button>
                <span aria-live="polite" className="p-paginator-current" data-pc-section="current">
                ({currentPage} {__('di', 'gepafin')} {totalPages})
            </span>
                <button
                    type="button"
                    disabled={nextDisabled}
                    onClick={nextDisabled ? () => {
                    } : () => handleClick(currentPage + 1)}
                    className={`p-paginator-next p-paginator-element p-link${nextDisabled ? ' p-disabled' : ''}`}
                    aria-label={__('Pagina successiva', 'gepafin')}
                    data-pc-section="nextpagebutton">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                         className="p-icon p-paginator-icon" aria-hidden="true" data-pc-section="nextpageicon">
                        <path
                            d="M5.25 11.1728C5.14929 11.1694 5.05033 11.1455 4.9592 11.1025C4.86806 11.0595 4.78666 10.9984 4.72 10.9228C4.57955 10.7822 4.50066 10.5916 4.50066 10.3928C4.50066 10.1941 4.57955 10.0035 4.72 9.86283L7.72 6.86283L4.72 3.86283C4.66067 3.71882 4.64765 3.55991 4.68275 3.40816C4.71785 3.25642 4.79932 3.11936 4.91585 3.01602C5.03238 2.91268 5.17819 2.84819 5.33305 2.83149C5.4879 2.81479 5.64411 2.84671 5.78 2.92283L9.28 6.42283C9.42045 6.56346 9.49934 6.75408 9.49934 6.95283C9.49934 7.15158 9.42045 7.34221 9.28 7.48283L5.78 10.9228C5.71333 10.9984 5.63193 11.0595 5.5408 11.1025C5.44966 11.1455 5.35071 11.1694 5.25 11.1728Z"
                            fill="currentColor"></path>
                    </svg>
                </button>
            </div> : null
    )
}

export default PaginatorBasic;