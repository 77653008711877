import React from 'react';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import { Password } from 'primereact/password';

const PasswordField = ({
                       fieldName,
                       label,
                       control,
                       errors,
                       defaultValue,
                       config = {},
                       infoText = null,
                       inputgroup = false,
                       icon = null,
                       placeholder = '',
                       disabled = false,
                       onBlurFn = () => {
                       }
                   }) => {
    const input = <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={config}
        render={({ field, fieldState }) => (
            <Password
                id={field.name}
                disabled={disabled}
                {...field}
                value={field.value ? field.value : ''}
                onBlur={onBlurFn}
                placeholder={placeholder}
                className={classNames({ 'p-invalid': fieldState.invalid })}
                toggleMask />
        )}/>
    return (
        <>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required || config.isRequired ? <span className="appForm__field--required">*</span> : null}
            </label>
            {inputgroup
                ? <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        {icon}
                    </span>
                    {input}
                </div>
                : input}
            {infoText ? <small>{infoText}</small> : null}
        </>)
}

export default PasswordField;