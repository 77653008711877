import React, { useRef } from 'react';

// components
import { Toolbar } from 'primereact/toolbar';
import { Link } from 'react-router-dom';
import LogoIcon from '../../../../icons/LogoIcon';
import { Button } from 'primereact/button';
import TopBarProfileMenu from '../../../../components/TopBarProfileMenu';
import NotificationsSidebar from '../../../../components/NotificationsSidebar';
import { useStore } from '../../../../store';
import { head } from 'ramda';

const AppTopbar = () => {
    const menuLeft = useRef(null);
    const companies = useStore().main.companies();
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const company = head(companies.filter(o => o.id === chosenCompanyId));

    const startContent = <Link to="/">
        <LogoIcon/>
    </Link>;

    const endContent = <div className="topBar__endContent">
        {company ? <span className="companyName">{company.companyName}</span> : null}
        {/*<IconField iconPosition="right">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText v-model="value1" placeholder={__('Cerca', 'gepafin')} disabled={true}/>
        </IconField>*/}
        <NotificationsSidebar/>
        {/*<i className="pi pi-envelope topBar__icon"></i>*/}
        {/*<i className="pi pi-envelope p-overlay-badge topBar__icon">
            <Badge severity="danger"></Badge>
        </i>*/}
        <Button
            type="button"
            className="topBar__profileBtn"
            outlined
            onClick={(event) => menuLeft.current.toggle(event)} aria-controls="topBar_profileMenu" aria-haspopup>
            <i className="pi pi-user"></i>
            <i className="pi pi-chevron-down"></i>
        </Button>
        <TopBarProfileMenu menuLeftRef={menuLeft}/>
    </div>

    return (
        <Toolbar start={startContent} end={endContent} className="topBar"/>
    )
}

export default AppTopbar;
