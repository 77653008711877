import * as Sentry from '@sentry/browser';

const LOCAL_DEVELOPMENT = process.env.REACT_APP_LOCAL_DEVELOPMENT;

const logMsgWithSentry = (endpoint = '', status = 0, resp = {}, error = null) => {
    try {
        if (LOCAL_DEVELOPMENT !== '1') {
            Sentry.init({
                dsn: 'https://e7b2134f7d816f663bb83e51b106a694@o4508381921738752.ingest.de.sentry.io/4508381935501392',
                environment: process.env.NODE_ENV || 'development'
            });

            if (!error) {
                const newError = new Error(`Status ${status}`);
                Sentry.captureException(`Error in endpoint: ${endpoint}`, {
                    level: 'error',
                    extra: {
                        originalError: newError,
                        details: resp
                    }
                });
            } else {
                Sentry.captureException(error);
            }
        }
    } catch (err) {
        console.log(err);
    }
}

export default logMsgWithSentry;