import { tokenize } from 'expression-language';

const getTokens = expr => tokenize(expr).tokens
    .filter((o, i, arr) => o.type === 'name'
        && (typeof arr[i+1] === 'undefined'
            || (typeof arr[i+1] !== 'undefined' && arr[i+1].value !== '('))
    )
    .map(o => o.value)
    .filter((v, i, arr) => arr.indexOf(v) === i);

export default getTokens;