import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class CompanyDocumentsService {

    static getCompanyDocuments = (companyId, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/companyDocument/company/${companyId}`, callback, errCallback, queryParams);
    };

    static uploadCompanyDocument = (id, body, callback, errCallback, queryParams) => {
        NetworkService.postMultiPart(`${API_BASE_URL}/companyDocument/company/${id}/upload`, body, callback, errCallback, queryParams);
    };

    static attachCompanyDocumentToAppl = (id, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/companyDocument/${id}/document/upload`, {}, callback, errCallback, queryParams);
    };

    static deleteCompanyDocument = (id, callback, errCallback) => {
        NetworkService.delete(`${API_BASE_URL}/companyDocument`, {}, callback, errCallback, [
            ['id', id]
        ]);
    };
}
