import mustache from 'mustache';
import { replace } from 'ramda';

/**
 * Use mustachejs to parse content and replace variables with their values
 * Each variable (everything in brackets) is expression for EE
 *
 * @param {string} content
 * @param {object} context
 *
 * @returns {string}
 */
const renderWithDataVars = (content = '', context = {}) => {
    try {
        let newContent = replace(/{/g, '{{&', content);
        newContent = replace(/}/g, '}}', newContent);
        return mustache.render(newContent, context);
    } catch {
        return content;
    }
};

export default renderWithDataVars;
