export const mimeTypes = [
    { name: 'PDF', code: '.pdf,application/pdf' },
    { name: 'p7m', code: '.p7m,application/pkcs7-mime,application/x-pkcs7-mime' },
    { name: 'ZIP', code: 'application/zip,.zip' },
    { name: 'Immagine', code: 'image/*' },
    {
        name: 'Word',
        code: '.doc,.docx,.odt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text'
    },
    {
        name: 'Excel',
        code: '.xls,.xlsx,.ods,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet'
    }
];

export const defaultMaxFileSize = 314572800; // 314572800 = 300 Mb

const dynamicDataForTextinput = [
    { label: 'ragione sociale', value: 'company.companyName' },
    { label: 'partita IVA', value: 'company.vatNumber' },
    { label: 'codice fiscale azienda', value: 'company.codiceFiscale' },
    { label: 'codice ateco', value: 'company.codiceAteco' },
    { label: 'indirizzo', value: 'company.address' },
    { label: 'numero di telefono azienda', value: 'company.phoneNumber' },
    { label: 'città', value: 'company.city' },
    { label: 'provincia', value: 'company.province' },
    { label: 'CAP', value: 'company.cap' },
    { label: 'paese', value: 'company.country' },
    { label: 'PEC', value: 'company.pec' },
    { label: 'email azienda', value: 'company.email' },
    { label: 'nome del referente', value: 'company.contactName' },
    { label: 'email del referente', value: 'company.contactEmail' },
    { label: 'email utente', value: 'user.email' },
    { label: 'nome utente', value: 'user.firstName' },
    { label: 'cognome utente', value: 'user.lastName' },
    { label: 'numero di telefono utente', value: 'user.phoneNumber' },
    { label: 'codice fiscale utente', value: 'user.codiceFiscale' },
    { label: 'nome del rappresentante', value: 'custom.legalRepresentant' },
    { label: 'nome e cognome utente', value: 'custom.userFullName' },
]

export const dynamicDataOptions = {
    datepicker: [
        { label: 'user dateOfBirth', value: 'user.dateOfBirth' }
    ],
    textinput: dynamicDataForTextinput,
    textarea: dynamicDataForTextinput
}

export const protocolType = [
    {
        'id': 1,
        'name': 'PROTOCOLLO ENTRATA'
    },
    {
        'id': 2,
        'name': 'PROTOCOLLO USCITA'
    },
    {
        'id': 3,
        'name': 'DOCUMENTO INTERNO'
    },
    /*{
        'id': 1003,
        'name': 'PROTOCOLLO INTERNO '
    }*/
];

export const classificationType = [
    {
        'idClassificazione': 101,
        'name': 'BILANCIO',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 102,
        'name': 'DICHHIARAZIONE DEI REDDITI',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 103,
        'name': 'SITUAZIONE CONTABILE',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 104,
        'name': 'PROSPETTO CONTO ECONOMICO',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 105,
        'name': 'CENTRALE DEI RISCHI',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 106,
        'name': 'RELAZIONE AZIENDALE ILLUSTRATIVA (MOD R1C, R1I, R1R, R1S A SECONDA DEI SETTORI)',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 107,
        'name': 'DOCUMENTO IDENTITA\'',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 108,
        'name': 'MODELLO SP1',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 109,
        'name': 'PRIVACY',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 110,
        'name': 'DOCUMENTAZIONE CHE ATTESTA POSSIBILITA\' DI RILASCIARE GARANZIE A FAVORE DI TERZI (ES. STATUTO, VISURA…)',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 111,
        'name': 'MODELLO AR1 D.LG 231/2007',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 112,
        'name': 'DOCUMENTO IDENTITA\' FIRMATARIO DICHIARAZIONE SOSTITUTIVA',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 113,
        'name': 'PRIVACY FIRMATARIO DICHIARAZIONE SOSTITUTIVA',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 114,
        'name': 'NULLAOSTA ANTIMAFIA',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 115,
        'name': 'VISURA CRIF',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 116,
        'name': 'DURC',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 117,
        'name': 'STATO PATRIMONIALE E CONTO ECONOMICO',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 118,
        'name': 'DOCUMENTAZIONE FISCALE E CONTABILE',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 119,
        'name': 'BILANCIO GRUPPO IMPRESE',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 121,
        'name': 'DICHIARAZIONE DEI REDDITI GARANTI',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 122,
        'name': 'CENTRALE DEI RISCHI GARANTI',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 123,
        'name': 'BILANCIO / DICHIARAZIONE REDDITI GARANTI',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 125,
        'name': 'CONTRATTO FIRMATO DAL CLIENTE',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 126,
        'name': 'CONTRATTO FIRMATO DA GEPAFIN',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 128,
        'name': 'ALLEGATO 4',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 129,
        'name': 'ATTESTAZIONE PREGIUDIZIEVOLI',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 130,
        'name': 'DELIBERA BANCA',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 131,
        'name': 'ALTRO',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 135,
        'name': 'DOMANDA PDF FIRMATO',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 136,
        'name': 'DELEGA-PROCURA BANDI',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 137,
        'name': 'DIGITAL ASSESMENT',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 202,
        'name': 'LETTERA DI GARANZIA',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 127,
        'name': 'FATTURA',
        'idTipoprotocollo': 2
    },
    {
        'idClassificazione': 201,
        'name': 'LETTERA ESITO DELIBERA',
        'idTipoprotocollo': 2
    },
    {
        'idClassificazione': 204,
        'name': 'LETTERA TRASPARENZA',
        'idTipoprotocollo': 2
    },
    {
        'idClassificazione': 205,
        'name': 'CONTRATTO',
        'idTipoprotocollo': 1
    },
    {
        'idClassificazione': 124,
        'name': 'STAMPA PROPOSTA DELIBERA',
        'idTipoprotocollo': 3
    },
    {
        'idClassificazione': 203,
        'name': 'GENERICO',
        'idTipoprotocollo': 3
    }
];