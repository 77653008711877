import React, { useState } from 'react';

// components
import { Chips } from 'primereact/chips';
import { isEmpty } from 'ramda';

const ElementSettingChips = ({ restrictedValues = [], changeFn, value = [] }) => {
    const [lastTyped, setLastTyped] = useState([])

    const isValidValue = (newVal) => {
        const validationRegex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
        return validationRegex.test(newVal);
    };

    const handleAdd = (e) => {
        const newValue = isEmpty(e.value) ? '' : e.value[e.value.length - 1];

        if (restrictedValues.includes(newValue)) {
            changeFn([]);
            return;
        }

        if (!isValidValue(newValue)) {
            changeFn(lastTyped);
            return;
        }

        setLastTyped(e.value)
        changeFn(e.value);
    };

    return (
        <div>
            <Chips
                value={value}
                addOnBlur={true}
                onChange={handleAdd}
                max={1}
            />
        </div>
    );
}

export default ElementSettingChips;