import React, { useEffect, useRef, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { isEmpty, pathOr, isNil } from 'ramda';
import { wrap } from 'object-path-immutable';

// store
import AppointmentService from '../../../../service/appointment-service';

// tools
import set404FromErrorResponse from '../../../../helpers/set404FromErrorResponse';

// components
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
//import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import { classificationType, protocolType } from '../../../../configData';
import { useStore } from '../../../../store';

const ArchiveDocument = ({
                             applicationId,
                             ndg = '',
                             fileId = 0,
                             fileDescr = '',
                             docAttachmentId = null,
                             updateFn = () => {}
}) => {
    const [loading, setLoading] = useState(false);
    const [isVisibleDialog, setIsVisibleDialog] = useState(false);
    const [modalData, setModalData] = useState({});
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const toast = useRef(null);
    const userData = useStore().main.userData();

    const openArchivationModal = () => {
        setIsVisibleDialog(true);
        setModalData({
            descrizione: fileDescr,
            idTipoProtocollo: 1,
            idClassificazione: 0,
            flagDaFirmare: false,
            email: userData.email,
            fileId
        });
    }

    const headerDialog = () => {
        return <span>{__('Archiviazione del documento', 'gepafin')}</span>;
    }

    const hideDialog = () => {
        setIsVisibleDialog(false);
        setModalData({});
    }

    const setValue = (name, value) => {
        const newData = wrap(modalData).set(name, value).value();
        setModalData(newData);
    }

    const footerDialog = () => {
        return <div>
            <Button type="button" label={__('Anulla', 'gepafin')} onClick={hideDialog} outlined/>
            <Button
                type="button"
                disabled={loading}
                label={__('Invia', 'gepafin')} onClick={submitData}/>
        </div>
    }

    const submitData = () => {
        if (
            modalData.idTipoProtocollo !== 0 && modalData.idClassificazione !== 0
            && !isEmpty(modalData.descrizione) && !isEmpty(modalData.email)
        ) {
            setLoading(true);
            const submitData = {
                'input': {
                    'idTipoProtocollo': modalData.idTipoProtocollo,
                    'idClassificazione': modalData.idClassificazione,
                    'flagDaFirmare': modalData.flagDaFirmare,
                    'descrizione': modalData.descrizione,
                    'attributes': {
                        'ndg': ndg,
                        'email': modalData.email
                    }
                }
            }

            AppointmentService.archiveDocument(applicationId, fileId, submitData, submitCallback, errSubmitCallback)
        }
    }

    const submitCallback = (data) => {
        if (data.status === 'SUCCESS') {
            if (toast.current && data.message) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: data.message
                });
            }
            const documentAttachmentId = pathOr('fake_id', ['documentAttachmentId'], data.data);
            updateFn(documentAttachmentId);
        }
        setIsVisibleDialog(false);
        setLoading(false);
    }

    const errSubmitCallback = (data) => {
        if (toast.current && data.message) {
            toast.current.show({
                severity: data.status === 'SUCCESS' ? 'info' : 'error',
                summary: '',
                detail: data.message
            });
        }
        setIsVisibleDialog(false);
        set404FromErrorResponse(data);
        setLoading(false);
    }

    useEffect(() => {
        const newModalData = {
            ...modalData,
            idClassificazione: 0
        };
        setModalData(newModalData);
        setCategories(classificationType
            .filter(o => o.idTipoprotocollo === modalData.idTipoProtocollo)
            .map(o => ({ value: o.idClassificazione, label: o.name })));
    }, [modalData.idTipoProtocollo])

    useEffect(() => {
        setTypes(protocolType.map(o => ({ value: o.id, label: o.name })));
    }, []);

    return (!isEmpty(ndg) && !isNil(ndg) && !docAttachmentId
            ? <>
                <Toast ref={toast}/>
                <Button icon="pi pi-file-export"
                        rounded
                        onClick={openArchivationModal}
                        outlined
                        severity="info"
                        aria-label={__('Mostra', 'gepafin')}/>
                <Dialog
                    visible={isVisibleDialog}
                    modal
                    header={headerDialog}
                    footer={footerDialog}
                    style={{ maxWidth: '600px', width: '100%' }}
                    onHide={hideDialog}>
                    <div className="appForm__field">
                        <label
                            className={classNames({ 'p-error': !modalData.idTipoProtocollo || modalData.idTipoProtocollo === 0 })}>
                            {__('Tipo di protocollo', 'gepafin')}*
                        </label>
                        <Dropdown
                            value={modalData.idTipoProtocollo}
                            invalid={!modalData.idTipoProtocollo || modalData.idTipoProtocollo === 0}
                            onChange={(e) => setValue('idTipoProtocollo', e.value)}
                            options={types}/>
                    </div>
                    <div className="appForm__field">
                        <label
                            className={classNames({ 'p-error': !modalData.idClassificazione || modalData.idClassificazione === 0 })}>
                            {__('Classificazione', 'gepafin')}*
                        </label>
                        <Dropdown
                            filter
                            value={modalData.idClassificazione}
                            invalid={!modalData.idClassificazione || modalData.idClassificazione === 0}
                            onChange={(e) => setValue('idClassificazione', e.value)}
                            options={categories}/>
                    </div>
                    {/*<div className="appForm__field">
                        <label>
                            {__('Da firmare?', 'gepafin')}
                        </label>
                        <InputSwitch
                            checked={modalData.flagDaFirmare}
                            onChange={(e) => setValue('flagDaFirmare', e.value)}/>
                    </div>*/}
                    <div className="appForm__field">
                        <label className={classNames({ 'p-error': isEmpty(modalData.descrizione) })}>
                            {__('Descrizione', 'gepafin')}*
                        </label>
                        <InputTextarea
                            value={modalData.descrizione}
                            invalid={isEmpty(modalData.descrizione)}
                            onChange={(e) => setValue('descrizione', e.target.value)}
                            rows={3}
                            cols={30}/>
                    </div>
                    <div className="appForm__field">
                        <label>
                            {__('NDG', 'gepafin')}*
                        </label>
                        <InputText
                            value={ndg}
                            disabled
                            onChange={() => {
                            }}/>
                    </div>
                    <div className="appForm__field">
                        <label className={classNames({ 'p-error': isEmpty(modalData.email) })}>
                            {__('Email', 'gepafin')}*
                        </label>
                        <InputText
                            value={modalData.email}
                            keyfilter="email"
                            invalid={isEmpty(modalData.email)}
                            onChange={(e) => setValue('email', e.target.value)}/>
                    </div>
                </Dialog>
            </> : null
    )
}

export default ArchiveDocument;