import React from 'react';
import { __ } from '@wordpress/i18n';
import { isNil } from 'ramda';

// components
import DownloadApplicationArchive from '../DownloadApplicationArchive';
import DownloadSignedApplication from '../DownloadSignedApplication';
import DownloadCompanyDelegation from '../DownloadCompanyDelegation';

const ApplicationDownloadFiles = ({ id }) => {
    return (
        !isNil(id)
            ? <div className="appPageSection">
                <h2>{__('Scarica documenti della domanda', 'gepafin')}</h2>
                <div className="appPageSection__row autoFlow">
                    <DownloadApplicationArchive applicationId={id}/>
                    <DownloadSignedApplication applicationId={id}/>
                    <DownloadCompanyDelegation applicationId={id}/>
                </div>
            </div> : null
    )
}

export default ApplicationDownloadFiles;