import { head, is, isNil, pathOr } from 'ramda';
import getNumberFormatted from '../../../../../../../helpers/getNumberFormatted';

const LastRowCell = ({columnId, lastRowCfg, columnMeta = {}, tableValue = []}) => {
    const cellData = head(lastRowCfg.filter(o => !isNil(o[columnId])));
    let cellValue = cellData[columnId];

    if (columnMeta.enableFormula) {
        cellValue = pathOr(0, ['total'], tableValue);
    }

    return <td>{is(Number, cellValue) ? getNumberFormatted(cellValue) : cellValue}</td>;
};

export default LastRowCell;