import React, { useEffect, useState, useCallback } from 'react';
import { __ } from '@wordpress/i18n';
import { is, isEmpty, pathOr } from 'ramda';

// api
import UserActionService from '../../../../service/user-action-service';

//
import getFormattedDateString from '../../../../helpers/getFormattedDateString';

// components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';

import translationStrings from '../../../../translationStringsForComponents';
import { useParams } from 'react-router-dom';

const UserActivityTableAsync = ({ chosenActivity = '', chosenPeriod = '' }) => {
    const { id } = useParams();
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [items, setItems] = useState(null);
    const [totalRecordsNum, setTotalRecordsNum] = useState(0);
    const [lazyState, setLazyState] = useState({
        first: 0,
        rows: 5,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            id: { value: null, matchMode: 'contains' },
            createdDate: { value: null, matchMode: 'date_is' },
            ipAddress: { value: null, matchMode: 'contains' }
        }
    });
    const statuses = ['SUBMIT', 'EVALUATION', 'SOCCORSO'];

    const getPaginationQuery = useCallback(() => {
        let sortBy = {
            columnName: "id",
            sortDesc: true
        };

        if (lazyState.sortField) {
            sortBy = {
                columnName: lazyState.sortField,
                sortDesc: lazyState.sortOrder === -1
            }
        }

        const timeFilter = chosenPeriod;
        const activityFilter = !isEmpty(chosenActivity) ? [chosenActivity] : [];

        return {
            globalFilters: {
                page: lazyState.page ? lazyState.page + 1 : 1,
                limit: lazyState.rows,
                sortBy
            },
            status: statuses,
            filters: Object.keys(lazyState.filters).reduce((acc, cur) => {
                const value = pathOr('', ['filters', cur, 'value'], lazyState);
                if (!isEmpty(value)) {
                    acc[cur] = lazyState.filters[cur];
                }
                return acc;
            }, {}),
            timeFilter,
            actionContext: activityFilter
        }
    }, [lazyState, chosenActivity, chosenPeriod]);

    const onPage = (event) => {
        setLazyState(event);
    };

    const onSort = (event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    };

    const onFilter = useCallback((event) => {
        event['first'] = 0;
        event['page'] = 0;
        setLazyState(event);
    }, [lazyState]);

    const getCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const { body, totalRecords,
                //currentPage, totalPages, pageSize
            } = resp.data;
            setTotalRecordsNum(totalRecords);
            setItems(getFormattedData(body));
        }
        setLocalAsyncRequest(false);
    }

    const errGetCallbacks = () => {
        setLocalAsyncRequest(false);
    }

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.createdDate = is(String, d.createdDate) ? new Date(d.createdDate) : (d.createdDate ? d.createdDate : '');
            return d;
        });
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999"/>;
    };

    const dateBodyTemplate = (rowData) => {
        return getFormattedDateString(rowData.createdDate);
    };

    useEffect(() => {
        if (!isEmpty(chosenActivity) && !isEmpty(chosenPeriod)) {
            setLocalAsyncRequest(true);
            const paginationQuery = getPaginationQuery();

            UserActionService.getUserActionPaginated(id, paginationQuery, getCallback, errGetCallbacks);
        }
    }, [lazyState, chosenActivity, chosenPeriod]);

    return (
        <div className="appPageSection__table">
            <DataTable
                value={items} stripedRows showGridlines
                lazy filterDisplay="menu" dataKey="id" paginator
                first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecordsNum} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                onFilter={onFilter} filters={lazyState.filters} loading={localAsyncRequest}
                emptyMessage={translationStrings.emptyMessage}>
                <Column header={__('Timestamp', 'gepafin')}
                        filterElement={dateFilterTemplate} filter
                        filterField="createdDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateBodyTemplate}/>
                <Column field="actionType" header={__('Tipo di attività', 'gepafin')}
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="actionContext" header={__('Azione', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="ipAddress" header={__('IP', 'gepafin')}
                        filter sortable
                        filterField="ipAddress"
                        filterMatchModeOptions={translationStrings.textFilterOptions}
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
            </DataTable>
        </div>
    )
}

export default UserActivityTableAsync;