import React, { useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { useLocation } from 'react-router-dom';

// store
import { useStore, storeSet, storeGet } from '../../store';

// components
import AppSidebar from './components/AppSidebar';
import AppTopbar from './components/AppTopbar';

const DefaultLayout = ({ children }) => {
    const isError404 = useStore().main.isError404();
    const location = useLocation();

    useEffect(() => {
        storeSet.main.isError404(false);
    }, [location]);

    return (
        <div className="wrapper">
            <AppTopbar/>
            <div className="inner">
                <AppSidebar/>
                <main>
                    {isError404
                        ? <p>Error 404</p>
                        : children}

                    <footer>
                        <p>{__('© 2024 Gepafin. Tutti i diritti riservati.', 'gepafin')}</p>
                    </footer>
                </main>
            </div>
        </div>
    )
}

export default DefaultLayout;