import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class ApplicationEvaluationService {

    static getEvaluationByApplId = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/applicationEvaluation/application`, callback, errCallback, queryParams);
    };

    static getEvaluationV2ByApplId = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/applicationEvaluation/v2`, callback, errCallback, queryParams);
    };

    static getEvaluationVersionByApplId = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/applicationEvaluation/application/${id}/version`, callback, errCallback, queryParams);
    };

    static updateEvaluation = (assignedApplicationId, body, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/applicationEvaluation/${assignedApplicationId}`, body, callback, errCallback, queryParams);
    };

    static updateEvaluationV2 = (assignedApplicationId, formId, body, callback, errCallback, queryParams = []) => {
        NetworkService.put(`${API_BASE_URL}/applicationEvaluation/v2/assignedApplication/${assignedApplicationId}`, body, callback, errCallback, [
            ['evaluationFormId', formId]
        ]);
    };
}
