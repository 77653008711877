import React from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { wrap } from 'object-path-immutable';
import { head, isEmpty, isNil, pathOr, sum } from 'ramda';

// components
import DefaultCell from './components/DefaultCell';
import LastRowCell from './components/LastRowCell';

const RenderTable = ({ tableValue = {}, columnsCfg, lastRowCfg, setTableValueFn, disabled }) => {
    const rows = pathOr([], ['rows'], tableValue)
    const table = useReactTable({
        data: rows,
        columns: columnsCfg,
        defaultColumn: {
            cell: DefaultCell
        },
        getCoreRowModel: getCoreRowModel(),
        meta: {
            disabled,
            updateData: (rowIndex, columnId, value) => {
                const columnCfgData = head(columnsCfg.filter(o => o.accessorKey === columnId));
                const cellData = head(lastRowCfg.filter(o => !isNil(o[columnId])));
                const cellValue = cellData[columnId];
                let newRowsData = wrap(tableValue).set(['rows', rowIndex, columnId], value).value();
                let total = pathOr(0, ['total'], tableValue);

                if (columnCfgData.meta.enableFormula) {
                    const getAllRowsValues = newRowsData.rows
                        .map(row => row[columnId])
                        .map(v => isEmpty(v) || isNil(v) ? 0 : v);

                    if (cellValue === 'sum') {
                        total = sum(getAllRowsValues);
                    } else {
                        total = 0;
                    }
                }

                newRowsData = wrap(newRowsData).set(['total'], total).value();
                setTableValueFn(newRowsData);
            },
        }
    });

    return (
        <table>
            <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        return (
                            <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                    <>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </>
                                )}
                            </th>
                        );
                    })}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map((row) => {
                return (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                            return (
                                <td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
            </tbody>
            {!isEmpty(lastRowCfg)
                ? <tfoot><tr>
                    {columnsCfg.map((o) => <LastRowCell
                        key={o.accessorKey}
                        columnId={o.accessorKey}
                        columnMeta={o.meta}
                        lastRowCfg={lastRowCfg}
                        tableValue={tableValue}/>)}
                </tr></tfoot>
                : null}
        </table>
    )
}

export default RenderTable