import React, { useState, useEffect} from 'react';
import { __ } from '@wordpress/i18n';
import { is, uniq, isNil, head } from 'ramda';
import { Link, useNavigate } from 'react-router-dom';

// api
import AssignedApplicationService from '../../../../service/assigned-application-service';

// tools
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getBandoSeverity from '../../../../helpers/getBandoSeverity';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';

import translationStrings from '../../../../translationStringsForComponents';
import { useStore } from '../../../../store';
import { klona } from 'klona';

const APP_HUB_ID = process.env.REACT_APP_HUB_ID;

const PreInstructorDomandeTable = ({ userId = null, statuses = [] }) => {
    const navigate = useNavigate();
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [statusesForFilter, setStatusesForFilter] = useState([]);
    const userData = useStore().main.userData();

    useEffect(() => {
        if (!isNil(userId)) {
            setLocalAsyncRequest(true);

            if (userId === 0) {
                AssignedApplicationService.getAssignedApplications(getCallback, errGetCallbacks, [
                    ['statuses', statuses]
                ]);
            } else {
                AssignedApplicationService.getAssignedApplications(getCallback, errGetCallbacks, [
                    ['userId', userId],
                    ['statuses', statuses]
                ]);
            }
        }
    }, [userId]);

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setItems(getFormattedData(data.data));
            setStatusesForFilter(uniq(data.data.map(o => o.status)))
            initFilters();
        }
        setLocalAsyncRequest(false);
    }

    const errGetCallbacks = (data) => {
        setLocalAsyncRequest(false);
    }

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.evaluationEndDate = is(String, d.evaluationEndDate) ? new Date(d.evaluationEndDate) : (d.evaluationEndDate ? d.evaluationEndDate : '');
            d.submissionDate = is(String, d.submissionDate) ? new Date(d.submissionDate) : (d.submissionDate ? d.submissionDate : '');
            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            callName: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            companyName: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            submissionDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            evaluationEndDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            applicationStatus: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined onClick={clearFilter} />
            </div>
        );
    };

    const dateAppliedBodyTemplate = (rowData) => {
        return formatDate(rowData.submissionDate);
    };

    const dateEndBodyTemplate = (rowData) => {
        return formatDate(rowData.evaluationEndDate);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.applicationStatus}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statusesForFilter} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder={translationStrings.selectOneLabel} className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)} />;
    };

    const handleInitiateEvaluation = (id) => {
        setLocalAsyncRequest(true);
        AssignedApplicationService.updateStatusAssignedApplication(id, getInitEvalCallback, errInitEvalCallbacks, [
            ['status', 'OPEN']
        ])
    }

    const getInitEvalCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            const evaluation = klona(head(getFormattedData([resp.data])));
            const newItems = items.map(o => o.id === evaluation.id ? evaluation : o);
            setItems(newItems);
            navigate(`/domande/${evaluation.applicationId}`);
        }
        setLocalAsyncRequest(false);
    }

    const errInitEvalCallbacks = (resp) => {
        setLocalAsyncRequest(false);
    }

    const actionsBodyTemplate = (rowData) => {
        if (rowData.status === 'AWAITING') {
            return <Button
                severity="info"
                onClick={() => handleInitiateEvaluation(rowData.id)}
                label={__('Valuta', 'gepafin')}
                icon="pi pi-eye"
                size="small"
                iconPos="right"/>
        } else {
            const label = ['OPEN', 'SOCCORSO'].includes(rowData.status) && userData.id === rowData.userId
                ? __('Valuta', 'gepafin')
                : __('Mostra', 'gepafin');
            return <Link to={`/domande/${rowData.applicationId}`}>
                <Button severity="info" label={label} icon="pi pi-eye" size="small" iconPos="right"/>
            </Link>
        }
    }

    const header = renderHeader();

    return(
        <div className="appPageSection__table">
            <DataTable value={items} paginator showGridlines rows={5} loading={localAsyncRequest} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       emptyMessage={translationStrings.emptyMessage}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="applicationId" header={__('ID domanda', 'gepafin')}
                        sortable filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                <Column field="protocolNumber" header={__('Protocollo', 'gepafin')}
                        sortable filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/>
                {APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE'
                    ? <Column field="ndg" header={__('NDG', 'gepafin')}
                        sortable filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/> : null}
                {APP_HUB_ID !== 't7jh5wfg9QXylNaTZkPoE'
                    ? <Column field="appointmentId" header={__('ID appuntamento', 'gepafin')}
                        sortable filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '6rem' }}/> : null}
                <Column field="callName" header={__('Bando', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column field="companyName" header={__('Azienda', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data ricezione', 'gepafin')} filterField="submissionDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateAppliedBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column header={__('Scadenza', 'gepafin')} filterField="evaluationEndDate" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateEndBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column field="applicationStatus" header={__('Stato', 'gepafin')}
                        style={{ minWidth: '7rem' }} body={statusBodyTemplate} filter
                        filterElement={statusFilterTemplate} />
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default PreInstructorDomandeTable;