import React, { useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import Quill from 'quill';
import { __ } from '@wordpress/i18n';

// components
import { Editor } from 'primereact/editor';
import BlockingOverlay from '../../../BlockingOverlay';
import { Button } from 'primereact/button';
import { isNil } from 'ramda';

const Delta = Quill.import('delta');

const Wysiwyg = ({
                     fieldName,
                     setDataFn,
                     label,
                     control,
                     rows = 3,
                     errors,
                     defaultValue,
                     config = {},
                     infoText = null,
                     placeholder = '',
                     disabled = false
                 }) => {
    const editorRef = useRef(null);

    const handleCleanValue = () => {
        setDataFn(fieldName, '', { shouldValidate: true });
    };

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                <button className="ql-list" value="ordered"></button>
                <button className="ql-header" value="2"></button>
                <button className="ql-blockquote"></button>
                <button className="ql-list" value="bullet"></button>
                <button className="ql-indent" value="-1"></button>
                <button className="ql-indent" value="+1"></button>
            </span>
        );
    };

    const header = renderHeader();

    return (
        <>
            <BlockingOverlay shouldDisplay={disabled}/>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required || config.isRequired ?
                <span className="appForm__field--required">*</span> : null}
            </label>
            {!isNil(setDataFn)
                ? <Button
                    label={__('Cancella testo', 'gepafin')}
                    type="button"
                    severity="warning"
                    outlined
                    onClick={handleCleanValue}
                /> : null}
            <div translate="no">
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={defaultValue}
                    rules={config}
                    render={({ field, fieldState }) => (
                        <Editor
                            ref={editorRef}
                            id={field.name}
                            readOnly={disabled}
                            {...field}
                            headerTemplate={header}
                            modules={{
                                clipboard: {
                                    matchers: [
                                        [Node.ELEMENT_NODE, function (node, delta) {
                                            const ops = delta.ops.map((op) => ({ insert: op.insert }));
                                            return new Delta(ops)
                                        }]
                                    ]
                                }
                            }}
                            placeholder={placeholder}
                            onTextChange={(e) => field.onChange(e.htmlValue)}
                            style={{ height: 80 * rows }}
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                        />
                    )}/>
            </div>
            {infoText ? <small>{infoText}</small> : null}
        </>
    )
}

export default Wysiwyg;