import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class EvaluationFormsService {

    static getFormForCall = (id, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/evaluationForm/call/${id}`, callback, errCallback);
    };

    static createFormForCall = (id, body, callback, errCallback) => {
        NetworkService.post(`${API_BASE_URL}/evaluationForm/call/${id}`, body, callback, errCallback);
    };

    static updateForm = (id, body, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/evaluationForm/${id}`, body, callback, errCallback, queryParams);
    };

    static getFormById = (id, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/evaluationForm/${id}`, callback, errCallback);
    };

    static deleteForm = (id, callback, errCallback) => {
        NetworkService.delete(`${API_BASE_URL}/evaluationForm/${id}`, {}, callback, errCallback);
    };
}
