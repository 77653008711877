import React from 'react';
import { __ } from '@wordpress/i18n';
import { Button } from 'primereact/button';
import getDateFromISOstring from '../../../../helpers/getDateFromISOstring';

const NotificationItemChosen = ({ item, closeFn, markReadFn }) => {
    return (
        <div className="notificationsSidebar__listItemChosen">
            <Button
                style={{marginBottom: '20px'}}
                type="button"
                outlined
                onClick={closeFn}
                label={__('Indietro', 'gepafin')}
                icon="pi pi-arrow-left" iconPos="left"/>
            <strong>{item.title}</strong>
            <span>{getDateFromISOstring(item.createdDate)}</span>
            {item.message}

            {item.status === 'UNREAD'
                ? <Button
                style={{marginTop: '20px'}}
                type="button"
                outlined
                onClick={() => markReadFn(item.id)}
                label={__('Letto', 'gepafin')}/> : null}
        </div>
    )
}

export default NotificationItemChosen;
