import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class AppointmentService {

    static getNdg = (applicationId, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/appointment/application/${applicationId}/check-ndg`, callback, errCallback, queryParams);
    };

    static archiveDocument = (applicationId, documentId, body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/appointment/document/${documentId}`, body, callback, errCallback, queryParams);
    };

    static createAppointment = (applicationId, body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/appointment/application/${applicationId}`, body, callback, errCallback, queryParams);
    };
}
