import React, { Component } from 'react';
import logMsgWithSentry from '../../helpers/logMsgWithSentry';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            builderError: false
        };
    }

    static getDerivedStateFromError(error) {
        try {
            logMsgWithSentry('', 0, {}, error);
        } catch (err) {
            console.log(err);
        }
        return { builderError: true };
    }

    render() {
        if (this.state.builderError) {
            return <div>Error</div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;