import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class BandoService {

    static getBandi = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/call`, callback, errCallback, queryParams);
    };

    static getBandiPaginated = (body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/call/pagination`, body, callback, errCallback, queryParams);
    };

    static getBando = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/call/${id}`, callback, errCallback, queryParams);
    };

    static validateBando = (id, callback, errCallback) => {
        NetworkService.post(`${API_BASE_URL}/call/validate/${id}`, {}, callback, errCallback);
    };

    static createBando = (body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/call/step1`, body, callback, errCallback, queryParams);
    };

    static updateBandoStep1 = (id, body, callback, errCallback) => {
        NetworkService.put(`${API_BASE_URL}/call/step1/${id}`, body, callback, errCallback);
    };

    static updateBandoStep2 = (id, body, callback, errCallback) => {
        NetworkService.put(`${API_BASE_URL}/call/step2/${id}`, body, callback, errCallback);
    };

    static updateBandoStep2V2 = (id, body, callback, errCallback) => {
        NetworkService.put(`${API_BASE_URL}/call/step2-v2/${id}`, body, callback, errCallback);
    };

    static updateBandoStatus = (id, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/call/${id}/status`, {}, callback, errCallback, queryParams);
    };

    static getBandoPdf = (id, callback, errCallback) => {
        NetworkService.getBlob(`${API_BASE_URL}/call/${id}/documents/zip`, callback, errCallback);
    };
}
