import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { createI18n, setLocaleData } from '@wordpress/i18n';
import { I18nProvider } from '@wordpress/react-i18n';
import './assets/scss/theme.scss';
import { isEmpty, head } from 'ramda';
import { addLocale, PrimeReactProvider } from 'primereact/api';

// store
import { useStore, storeSet, storeGet } from './store';

// api
import AuthenticationService from './service/authentication-service';

const i18n = createI18n({}, 'gepafin');

function App() {
    const role = useStore().main.getRole();
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const isRedirectedOnceNoCompany = useStore().main.isRedirectedOnceNoCompany();
    const value = {
        locale: 'it',
    };

    const callback = (data) => {
        if (data.status === 'SUCCESS') {
            storeSet.main.userData(data.data);
        } else {
            storeSet.main.doLogout();
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
    }

    useEffect(() => {
        if (['ROLE_BENEFICIARY', 'ROLE_CONFIDI'].includes(role)) {
            const userData = storeGet.main.userData();
            if (userData.companies && !isEmpty(userData.companies)) {
                storeSet.main.companies(userData.companies);
                const company = head(userData.companies.filter(o => o.id === chosenCompanyId));

                if (!company) {
                    storeSet.main.chosenCompanyId(userData.companies[0].id);
                }
            } else {
                storeSet.main.chosenCompanyId(0);
                const { origin, href } = window.location;
                const url = `${origin}/agguingi-azienda`;

                if (!isRedirectedOnceNoCompany && url !== href) {
                    storeSet.main.isRedirectedOnceNoCompany(true);
                    window.location.replace('/agguingi-azienda')
                }
            }
        }
    }, [role]);

    useEffect(() => {
        storeSet.main.setAsyncRequest();
        AuthenticationService.me(callback, errCallback);

        addLocale('it', {
            startsWith: 'Inizia con',
            contains: 'Contiene',
            notContains: 'Non contiene',
            endsWith: 'Finisce con',
            equals: 'Uguale a',
            notEquals: 'Diverso da',
            noFilter: 'Nessun filtro',
            lt: 'Minore di',
            lte: 'Minore o uguale a',
            gt: 'Maggiore di',
            gte: 'Maggiore o uguale a',
            dateIs: 'Data uguale a',
            dateIsNot: 'Data diversa da',
            dateBefore: 'Data prima di',
            dateAfter: 'Data dopo',
            custom: 'Personalizzato',
            clear: 'Cancella',
            apply: 'Applica',
            matchAll: 'Tutte le condizioni',
            matchAny: 'Qualsiasi condizione',
            addRule: 'Aggiungi regola',
            removeRule: 'Rimuovi regola',
            accept: 'Sì',
            reject: 'No',
            choose: 'Scegli',
            upload: 'Carica',
            cancel: 'Annulla',
            dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
            dayNamesMin: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
            monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
            today: 'Oggi',
            weekHeader: 'Sm',
            firstDayOfWeek: 1,
            dateFormat: 'dd/mm/yy',
            weak: 'Debole',
            medium: 'Medio',
            strong: 'Forte',
            passwordPrompt: 'Inserisci una password',
            emptyMessage: 'Nessun risultato trovato',
            emptyFilterMessage: 'Nessun risultato trovato'
        });

        fetch('/languages/en_US.json')
            .then((res) => res.json())
            .then(res => {
                setLocaleData(res.locale_data['gepafin'], 'gepafin')
            })
    }, []);

    return (
        <I18nProvider i18n={i18n}>
            <BrowserRouter>
                <PrimeReactProvider value={value}>
                    <Routes role={role} chosenCompanyId={chosenCompanyId}/>
                </PrimeReactProvider>
            </BrowserRouter>
        </I18nProvider>
    );
}

export default App;
