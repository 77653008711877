import { __ } from '@wordpress/i18n';

const getBandoLabel = (status) => {
    switch (status) {
        case 'ACTIVE':
            return __('Attivo', 'gepafin');

        case 'SUBMIT':
            return __('Inviato', 'gepafin');

        case 'PUBLISH':
            return __('Pubblicato', 'gepafin');

        case 'APPROVED':
            return __('Approvato', 'gepafin');

        case 'VALID':
            return __('Valido', 'gepafin');

        case 'READY_TO_PUBLISH':
            return __('Pronto', 'gepafin');

        case 'READY':
            return __('Pronto', 'gepafin');

        case 'NDG':
            return __('NDG', 'gepafin');

        case 'APPOINTMENT':
            return __('Appuntamento', 'gepafin');

        case 'ADMISSIBLE':
            return __('Ammisibile', 'gepafin');

        case 'TECHNICAL_EVALUATION':
            return __('Valutazione technico-finanziaria', 'gepafin');

        case 'DUE':
            return __('In scadenza', 'gepafin');

        case 'RESPONSE_RECEIVED':
            return __('Riposta ricevuta', 'gepafin');

        case 'SOCCORSO':
            return __('Soccorso', 'gepafin');

        case 'DRAFT':
            return __('Bozza', 'gepafin');

        case 'AWAITING':
            return __('In attesa', 'gepafin');

        case 'AWAIT':
            return __('In attesa', 'gepafin');

        case 'OPEN':
            return __('Aperto', 'gepafin');

        case 'ASSIGNED':
            return __('Assegnato', 'gepafin');

        case 'EVALUATION':
            return __('Valutazione', 'gepafin');

        case 'EXPIRED':
            return __('Scaduto', 'gepafin');

        case 'CLOSE':
            return __('Chiuso', 'gepafin');

        case 'REJECTED':
            return __('Respinto', 'gepafin');

        default:
            return '';
    }
};

export default getBandoLabel;