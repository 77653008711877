import React from 'react';
import { __ } from '@wordpress/i18n';

// store
import { useStore } from '../../store';

// components
import PreInstructorDomandeTable from '../DashboardPreInstructor/components/PreInstructorDomandeTable';

const DomandeArchivePreInstructor = () => {
    const userData = useStore().main.userData();

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Archivio domande', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <h2>{__('Domande completate', 'gepafin')}</h2>
                <PreInstructorDomandeTable statuses={['CLOSE']} userId={userData.id}/>
            </div>
        </div>
    )
}

export default DomandeArchivePreInstructor;