import { InputText } from 'primereact/inputtext';

const DefaultCell = ({ getValue, row: { index }, column: { id }, table }) => {
    const initialValue = getValue();
    const disabled = table.options.meta?.disabled;

    const onFocus = (e) => {
        e.target.select();
    }

    return (
        <InputText
            value={initialValue ?? ''}
            disabled={disabled}
            onFocus={onFocus}
            onChange={(e) => table.options.meta?.updateData(index, id, e.target.value)} />
    );
};

export default DefaultCell;