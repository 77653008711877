import React from 'react';
import { __ } from '@wordpress/i18n';

// components
import AllBandiTable from './components/AllBandiTable';

const BandiPreInstructor = () => {
    return(
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Bandi attivi', 'gepafin')}</h1>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection">
                <AllBandiTable/>
            </div>
        </div>
    )
}

export default BandiPreInstructor;