import React, { useState, useEffect} from 'react';
import { __ } from '@wordpress/i18n';
import { uniq } from 'ramda';

// api
import BandoService from '../../../../service/bando-service';

// tools
import getTimeParsedFromString from '../../../../helpers/getTimeParsedFromString';
import getTimeFromISOstring from '../../../../helpers/getTimeFromISOstring';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import translationStrings from '../../../../translationStringsForComponents';


const LatestBandiTable = () => {
    const [items, setItems] = useState(null);
    const [filters, setFilters] = useState(null);
    const [localAsyncRequest, setLocalAsyncRequest] = useState(false);
    const [, setStatuses] = useState([]);

    useEffect(() => {
        setLocalAsyncRequest(true);
        BandoService.getBandi(getCallback, errGetCallbacks);
    }, []);

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const newItems = data.data.filter(o => o.status === 'PUBLISH');
            setItems(getFormattedBandiData(newItems));
            setStatuses(uniq(data.data.map(o => o.status)));
            initFilters();
        }
        setLocalAsyncRequest(false);
    }

    const errGetCallbacks = (data) => {
        setLocalAsyncRequest(false);
    }

    const getFormattedBandiData = (data) => {
        return [...(data || [])].map((d) => {
            d.start_date = new Date(d.dates[0]);
            d.end_date = new Date(d.dates[1]);

            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            start_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            end_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined onClick={clearFilter} />
            </div>
        );
    };

    const dateStartBodyTemplate = (rowData) => {
        const startTimeObj = getTimeParsedFromString(rowData.startTime);
        return formatDate(rowData.start_date) + ' ' + getTimeFromISOstring(startTimeObj);
    };

    const dateEndBodyTemplate = (rowData) => {
        const endTimeObg = getTimeParsedFromString(rowData.endTime);
        return formatDate(rowData.end_date)  + ' ' + getTimeFromISOstring(endTimeObg);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const header = renderHeader();

    return(
        <div className="appPageSection__table">
            <DataTable value={items}
                       paginator showGridlines
                       rows={5} loading={localAsyncRequest} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       emptyMessage={translationStrings.emptyMessage}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="name" header={__('Nome Bando', 'gepafin')}
                        filter sortable
                        filterPlaceholder={__('Cerca il nome', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data Pubblicazione', 'gepafin')} filterField="start_date" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateStartBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column header={__('Data Scadenza', 'gepafin')} filterField="end_date" dataType="date"
                        style={{ minWidth: '8rem' }}
                        body={dateEndBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')}
                        style={{ minWidth: '7rem' }} body={statusBodyTemplate} />
            </DataTable>
        </div>
    )
}

export default LatestBandiTable;