import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class AssignedApplicationService {

    static getAssignedApplications = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/assignedApplication`, callback, errCallback, queryParams);
    };

    static assignApplication = (id, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/assignedApplication/application/${id}`, {}, callback, errCallback, queryParams);
    };

    static updateStatusAssignedApplication = (id, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/assignedApplication/${id}/status`, {}, callback, errCallback, queryParams);
    };

    static assignApplicationPaginated = (body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/assignedApplication/pagination`, body, callback, errCallback, queryParams);
    };
}
