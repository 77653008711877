import React from 'react';
import getDateFromISOstring from '../../../../helpers/getDateFromISOstring';

const NotificationItem = ({ item, clickFn }) => {
    const handleClick = () => {
        clickFn(item.id);
    }

    return (
        <li className="notificationsSidebar__listItem" onClick={handleClick}>
            <div className="notificationsSidebar__listItemContent">
                {item.status === 'READ'
                    ? <p>{item.title}</p>
                    : <strong>{item.title}</strong>}
                <span>{getDateFromISOstring(item.createdDate)}</span>
            </div>
            <i className="pi pi-angle-right"></i>
        </li>
    )
}

export default NotificationItem;
