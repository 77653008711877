import React from 'react';
import { __ } from '@wordpress/i18n';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { isEmpty } from 'ramda';

// components


const ChartRichiesteVsApprovate = ({ title, data = [] }) => {

    // Custom tooltip
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="chartCard__tooltip">
                    <p className="chartCard__tooltipTitle">{label}</p>
                    <p className="chartCard__tooltipText">
                        {__('In bozza', 'gepafin')}: {payload[0].value}
                    </p>
                    <p className="chartCard__tooltipText">
                        {__('Inviate', 'gepafin')}: {payload[1].value}
                    </p>
                </div>
            );
        }
        return null;
    };

    return (<div className="chartCard">
        {title ? <span className="chartCard__title">{title}</span> : null}
        {data && !isEmpty(data)
            ? <div className="chartCard__chart">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 60,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey="month"/>
                        <YAxis/>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Legend/>
                        <Bar dataKey="totalRequested" fill="#8884d8" stackId="a" name={__('Richiesti', 'gepafin')}/>
                        <Bar dataKey="totalApproved" fill="#EEC137" stackId="a" name={__('Approvati', 'gepafin')}/>
                    </BarChart>
                </ResponsiveContainer>
            </div> : null}
    </div>)
}

export default ChartRichiesteVsApprovate;