import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class DocumentCategoryService {

    static getCategories = (callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/documentCategory`, callback, errCallback);
    };
}
