import React, { useState, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import { head, is, uniq } from 'ramda';

// store
import { useStore } from '../../../../store';

// tools
import getBandoSeverity from '../../../../helpers/getBandoSeverity';
import getBandoLabel from '../../../../helpers/getBandoLabel';
import getDateFromISOstring from '../../../../helpers/getDateFromISOstring';

// api
import CompanyDocumentsService from '../../../../service/company-documents-service';

// components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import ProperBandoLabel from '../../../../components/ProperBandoLabel';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';

const DocumentsTable = ({ type, reload = 0 }) => {
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const companies = useStore().main.companies();
    const [docs, setDocs] = useState([]);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        const existingCompany = head(companies.filter(o => o.id === chosenCompanyId));

        if (!loading && existingCompany && reload !== 0) {
            setLoading(true);
            CompanyDocumentsService.getCompanyDocuments(chosenCompanyId, getCallback, errGetCallbacks, [
                ['documentType', type]
            ]);
        }
    }, [chosenCompanyId, reload, companies]);

    useEffect(() => {
        const existingCompany = head(companies.filter(o => o.id === chosenCompanyId));

        if (existingCompany) {
            setLoading(true);
            CompanyDocumentsService.getCompanyDocuments(chosenCompanyId, getCallback, errGetCallbacks, [
                ['documentType', type]
            ]);
        }
    }, []);

    const getCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            setDocs(getFormattedData(resp.data));
            setStatuses(uniq(resp.data.map(o => o.status)))
            initFilters();
        }
        setLoading(false);
    }

    const errGetCallbacks = () => {
        setLoading(false);
    }

    const getFormattedData = (data) => {
        return data.map((d) => {
            d.callEndDate = is(String, d.callEndDate) ? new Date(d.callEndDate) : (d.callEndDate ? d.callEndDate : '');
            return d;
        });
    };

    const clearFilter = () => {
        initFilters();
    };

    const initFilters = () => {
        setFilters({
            name: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
            },
            createdDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            },
            expirationDate: {
                operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
            }
        });
    };

    const renderHeader = () => {
        return (
            <div className="appTableHeader">
                <Button type="button" icon="pi pi-filter-slash" label={__('Pulisci', 'gepafin')} outlined
                        onClick={clearFilter}/>
            </div>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return getDateFromISOstring(rowData.createdDate);
    };
    const dateExpirationBodyTemplate = (rowData) => {
        return getDateFromISOstring(rowData.expirationDate);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999"/>;
    };

    const catBodyTemplate = (rowData) => {
        return rowData.category.categoryName;
    };

    const statusBodyTemplate = (rowData) => {
        return <ProperBandoLabel status={rowData.status}/>;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         itemTemplate={statusItemTemplate} placeholder={__('Scegli uno', 'gepafin')}
                         className="p-column-filter"
                         showClear/>;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={getBandoLabel(option)} severity={getBandoSeverity(option)}/>;
    };

    const handleDeleteFile = (id) => {
        setLoading(true);
        CompanyDocumentsService.deleteCompanyDocument(id,(resp) => deleteCallback(resp, id), errDeleteCallback)
    }

    const deleteCallback = (resp, deletedId) => {
        if (resp.status === 'SUCCESS') {
            setDocs(docs.filter(o => o.id !== deletedId));
        }
        setLoading(false);
    }

    const errDeleteCallback = () => {
        setLoading(false);
    }

    const actionsBodyTemplate = (rowData) => {
        return <div className="appPageSection__iconActions">
            <Button icon="pi pi-eye" rounded
                    onClick={() => {
                        window.open(rowData.filePath, '_blank').focus()
                    }}
                    outlined severity="info"
                    aria-label={__('Mostra', 'gepafin')}/>
            {/*<Button icon="pi pi-sync" rounded
                    onClick={() => {}}
                    outlined severity="success"
                    aria-label={__('Aggiorna', 'gepafin')}/>*/}
            <ConfirmPopup/>
            <Button icon="pi pi-trash" rounded
                    onClick={(event) => confirmDelete(event, rowData.id)}
                    outlined severity="danger"
                    aria-label={__('Cancella', 'gepafin')}/>
        </div>
    }

    const confirmDelete = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: __('Sei sicuro di voler rimuovere il file?', 'gepafin'),
            acceptLabel: __('Si', 'gepafin'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => {
                handleDeleteFile(id);
            },
            reject: () => {
            }
        });
    };

    const header = renderHeader();

    return (
        <div className="appPageSection__table">
            <DataTable value={docs} paginator showGridlines rows={5} loading={loading} dataKey="id"
                       filters={filters} stripedRows removableSort
                       header={header}
                       onFilter={(e) => setFilters(e.filters)}>
                <Column field="name"
                        header={__('Nome documento', 'gepafin')}
                        filter filterField="name"
                        filterPlaceholder={__('Cerca per nome', 'gepafin')}
                        style={{ minWidth: '12rem' }}/>
                <Column body={catBodyTemplate} header={__('Categoria', 'gepafin')}
                        style={{ minWidth: '8rem' }}/>
                <Column header={__('Data caricamento', 'gepafin')}
                        filterField="createdDate" dataType="date"
                        style={{ minWidth: '7rem' }}
                        body={dateCreatedBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column header={__('Data scadenza', 'gepafin')}
                        filterField="expirationDate" dataType="date"
                        style={{ minWidth: '7rem' }}
                        body={dateExpirationBodyTemplate} filter filterElement={dateFilterTemplate}/>
                <Column field="status" header={__('Stato', 'gepafin')}
                        filterMenuStyle={{ width: '14rem' }}
                        style={{ width: '120px' }} body={statusBodyTemplate}
                        filterElement={statusFilterTemplate}/>
                <Column header={__('Azioni', 'gepafin')}
                        body={actionsBodyTemplate}/>
            </DataTable>
        </div>
    )
}

export default DocumentsTable;
