import React from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { wrap } from 'object-path-immutable';
import { isEmpty } from 'ramda';

// components
import DefaultCell from './components/DefaultCell';
import LastRowCell from './components/LastRowCell';

const RenderTable = ({ rowsData = [], columnsCfg, lastRowCfg, setRowsFn, disabled }) => {
    const table = useReactTable({
        data: rowsData,
        columns: columnsCfg,
        defaultColumn: {
            cell: DefaultCell
        },
        getCoreRowModel: getCoreRowModel(),
        meta: {
            disabled,
            updateData: (rowIndex, columnId, value) => {
                const newRowsData = wrap(rowsData).set([rowIndex, columnId], value).value();
                setRowsFn(newRowsData);
            },
        }
    });

    const getColumnData = (columnId) => {
        const rows = table.getRowModel().rows;
        return rows.map(row => row.getValue(columnId));
    };

    return (
        <table>
            <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        return (
                            <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder ? null : (
                                    <>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </>
                                )}
                            </th>
                        );
                    })}
                </tr>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map((row) => {
                return (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                            return (
                                <td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
            </tbody>
            {!isEmpty(lastRowCfg)
                ? <tfoot><tr>
                    {columnsCfg.map((o) => <LastRowCell
                        key={o.accessorKey}
                        columnId={o.accessorKey}
                        columnMeta={o.meta}
                        lastRowCfg={lastRowCfg}
                        getColumnDataFn={getColumnData}/>)}
                </tr></tfoot>
                : null}
        </table>
    )
}

export default RenderTable