import React, { useState, useEffect, useRef, useMemo } from 'react';
import { __, sprintf } from '@wordpress/i18n';
import { useParams } from 'react-router-dom';
import { head, isEmpty, isNil, pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import 'quill/dist/quill.core.css';

// store
import { storeSet, useStore } from '../../store';

// api
import ApplicationService from '../../service/application-service';

// tools
import {
    isPIVA,
    isCodiceFiscale,
    isCAP,
    isIBAN,
    isEmail,
    isEmailPEC,
    isUrl,
    isMarcaDaBollo, minChecks, maxChecks, nonEmptyTables
} from '../../helpers/validators';
import renderHtmlContent from '../../helpers/renderHtmlContent';
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';

// components
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import FormField from '../../components/FormField';
import { Toast } from 'primereact/toast';
import { Messages } from 'primereact/messages';
import ApplicationSteps from '../BandoApplication/ApplicationSteps';
import BlockingOverlay from '../../components/BlockingOverlay';
import { klona } from 'klona';
import getTokens from '../../helpers/getTokens';
import renderWithDataVars from '../../helpers/renderWithDataVars';
import { evaluate } from 'mathjs';
import equal from 'fast-deep-equal';
import parseCommaDecimal from '../../helpers/parseCommaDecimal';

const BandoApplicationPreview = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState([]);
    const [formInitialData, setFormInitialData] = useState(null);
    const [bandoTitle, setBandoTitle] = useState('');
    const [, setBandoId] = useState(0);
    const [formId, setFormId] = useState('');
    const [totalSteps, setTotalSteps] = useState(0);
    const [applicationStatus, setApplicationStatus] = useState('');
    const [activeStep, setActiveStep] = useState(1);
    const chosenCompanyId = useStore().main.chosenCompanyId();
    const role = useStore().main.getRole();
    const isAsyncRequest = useStore().main.isAsyncRequest();
    const toast = useRef(null);
    const formMsgs = useRef(null);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
        register,
        getValues,
        reset,
        watch
    } = useForm({
        defaultValues: useMemo(() => {
            return formInitialData ? formInitialData : {}
        }, [formInitialData]),
        mode: 'onChange'
    });
    const validationFns = {
        isPIVA,
        isCodiceFiscale,
        isCAP,
        isIBAN,
        isEmail,
        isEmailPEC,
        isUrl,
        isMarcaDaBollo,
        minChecks,
        maxChecks,
        nonEmptyTables
    }
    const activeStepIndex = activeStep - 1;
    const values = getValues();
    const formValues = watch();

    const onValidate = () => {
        const applId = getApplicationId();
        storeSet.main.setAsyncRequest();
        formMsgs.current.clear();

        ApplicationService.validateApplication(applId, {}, validateApplicationCallback, errValidateApplicationCallback);
    };

    /*const onSubmit = () => {
    };*/

    const validateApplicationCallback = (data) => {
        if (data.status === 'SUCCESS') {
            toast.current.show({
                severity: 'success',
                summary: '',
                detail: data.message
            });
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errValidateApplicationCallback = (data) => {
        if (toast.current) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
        storeSet.main.unsetAsyncRequest();
    }

    const saveDraft = (saveAndMove = '') => {
        trigger();
    }

    const getApplicationId = () => {
        const parsed = parseInt(id)
        return !isNaN(parsed) ? parsed : 0;
    }

    const goBackward = () => {
        storeSet.main.setAsyncRequest();
        const queryParams = 'ROLE_BENEFICIARY' === role
            ? [
                ['formId', formId],
                ['companyId', chosenCompanyId],
                ['action', 'PREVIOUS']
            ]
            : [
                ['formId', formId],
                ['action', 'PREVIOUS']
            ];

        ApplicationService.getApplicationForm(id, getApplFormCallback, errGetApplFormCallbacks, queryParams);
    }

    const goForward = () => {
        storeSet.main.setAsyncRequest();
        const queryParams = 'ROLE_BENEFICIARY' === role
            ? [
                ['formId', formId],
                ['companyId', chosenCompanyId],
                ['action', 'NEXT']
            ]
            : [
                ['formId', formId],
                ['action', 'NEXT']
            ];

        ApplicationService.getApplicationForm(id, getApplFormCallback, errGetApplFormCallbacks, queryParams);
    }

    const getApplFormCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setBandoTitle(data.data.callTitle);
            setBandoId(data.data.callId);
            setFormData(data.data.applicationFormResponse.content);
            setFormId(data.data.formId);
            setTotalSteps(data.data.totalFormSteps);
            setApplicationStatus(data.data.applicationStatus)
            setActiveStep(data.data.currentStep);

            /*const chosenCompanyId = storeGet.main.chosenCompanyId();
            const companies = storeGet.main.companies();
            const company = head(companies.filter(o => o.id === chosenCompanyId));*/
            let formDataInitial = {};
            let dynamicData = {
                company: {},
                user: {}
            };

            if (data.data.applicationFormResponse.content) {
                // eslint-disable-next-line array-callback-return
                data.data.applicationFormResponse.content.map((o) => {
                    if (o.dynamicData && !isEmpty(o.dynamicData)) {
                        formDataInitial[o.id] = pathOr('', o.dynamicData.split('.'), dynamicData);
                    }
                })
            }

            if (data.data.applicationFormResponse.formFields) {
                const submitData = data.data.applicationFormResponse.formFields.map((o) => ({
                    fieldId: o.fieldId,
                    fieldValue: o.fieldValue
                }));
                formDataInitial = submitData.reduce((acc, cur) => {
                    if (cur.fieldValue) {
                        acc[cur.fieldId] = cur.fieldValue;
                    }
                    return acc;
                }, formDataInitial);
            }

            reset();
            setFormInitialData(formDataInitial);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetApplFormCallbacks = (data) => {
        storeSet.main.unsetAsyncRequest();
        if (data.status === 'VALIDATION_ERROR') {
            if (toast.current) {
                toast.current.show({
                    severity: 'error',
                    summary: '',
                    detail: data.message
                });
            }
        } else {
            set404FromErrorResponse(data);
        }
    }

    const onDownloadApplicationPdf = () => {
        const applId = getApplicationId();
        storeSet.main.setAsyncRequest();

        ApplicationService.downloadApplicationPdf(applId, {}, getPdfCallback, errPdfCallback);
    }

    const getPdfCallback = (data) => {
        const applId = getApplicationId();
        const pdfFile = new Blob([data], { type: 'application/octet-stream' })
        const url = window.URL.createObjectURL(pdfFile);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `application-${applId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        storeSet.main.unsetAsyncRequest();
    }

    const errPdfCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const actionBtns = <div className="appPageSection__actions">
        {activeStep > 1 && activeStep <= totalSteps
            ? <Button
                type="button"
                onClick={goBackward}
                label={__('Vai indietro', 'gepafin')}
                icon="pi pi-arrow-left"
                iconPos="left"/> : null}
        <Button
            type="button"
            disabled={isAsyncRequest}
            onClick={saveDraft}
            outlined
            label={__('Controlla', 'gepafin')} icon="pi pi-verified" iconPos="right"/>
        {activeStep < totalSteps
            ? <Button
                type="button"
                onClick={goForward}
                label={__('Vai avanti', 'gepafin')}
                icon="pi pi-arrow-right"
                iconPos="right"/> : null}
        <Button
            type="button"
            onClick={onDownloadApplicationPdf}
            label={__('Scarica PDF', 'gepafin')}
            icon="pi pi-download"
            iconPos="right"/>
    </div>

    useEffect(() => {
        let updatedFormValues = klona(formValues);
        let context = {};

        // eslint-disable-next-line array-callback-return
        formData.map((o) => {
            const variable = head(o.settings.filter(o => o.name === 'variable'));
            const formula = head(o.settings.filter(o => o.name === 'formula'));

            if (formula && !isEmpty(formula.value)) {
                context = getTokens(formula.value)
                    .filter(v => !['false', 'null', 'true'].includes(v))
                    .reduce((acc, cur) => {
                        acc[cur] = isNil(context[cur]) ? 0 : parseCommaDecimal(context[cur]);
                        return acc;
                    }, context);
                const mathFormula = renderWithDataVars(formula.value, context);
                try {
                    updatedFormValues[o.id] = evaluate(mathFormula);
                } catch (e) {
                    console.log('Error in math formula: "', mathFormula, '"', e.message);
                    updatedFormValues[o.id] = 0;
                }
            }

            if (variable && !isEmpty(variable.value)) {
                context[variable.value[0]] = 'criteria_table' === o.name
                    ? pathOr(0, [o.id, 'total'], updatedFormValues)
                    : pathOr(0, [o.id], updatedFormValues);
            }
        });

        if (!isEmpty(updatedFormValues) && !equal(updatedFormValues, formValues)) {
            reset(updatedFormValues);
        }
    }, [formValues]);

    useEffect(() => {
        if (formInitialData) {
            //reset();
            Object.keys(formInitialData).map(k => setValue(k, formInitialData[k]));
            trigger();
        }
    }, [formInitialData]);

    useEffect(() => {
        const applId = getApplicationId();

        if (applId) {
            storeSet.main.setAsyncRequest();
            const queryParams = ['ROLE_BENEFICIARY', 'ROLE_CONFIDI'].includes(role)
                ? [
                    ['companyId', chosenCompanyId]
                ]
                : [];
            ApplicationService.getApplicationForm(applId, getApplFormCallback, errGetApplFormCallbacks, queryParams);
        }
    }, [id]);

    return (
        <div className="appPage">
            {!isAsyncRequest
                ? <div className="appPage__pageHeader">
                    <h1>{sprintf(__('Domanda per il Bando: %s', 'gepafin'), bandoTitle)}</h1>
                </div>
                : <>
                    <Skeleton width="20%" height="1rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" height="2rem" className="mb-8"></Skeleton>
                </>}

            <div className="appPage__spacer"></div>

            <ApplicationSteps totalSteps={totalSteps} activeStepIndex={activeStepIndex}/>

            <div className="appPage__spacer"></div>

            <Messages ref={formMsgs}/>
            <Toast ref={toast}/>

            <div className="appPage__content">
                <BlockingOverlay shouldDisplay={isAsyncRequest}/>
                <form className="appForm" onSubmit={handleSubmit(onValidate)}>
                    <div className="appPageSection__preview">
                        {actionBtns}
                    </div>

                    {formData.map(o => {
                        const label = head(o.settings.filter(o => o.name === 'label'));
                        const text = head(o.settings.filter(o => o.name === 'text'));
                        const placeholder = head(o.settings.filter(o => o.name === 'placeholder'));
                        const options = head(o.settings.filter(o => o.name === 'options'));
                        let tableColumns = head(o.settings.filter(o => o.name === 'table_columns'));
                        if (!tableColumns) {
                            tableColumns = head(o.settings.filter(o => o.name === 'criteria_table_columns'));
                        }
                        const step = head(o.settings.filter(o => o.name === 'step'));
                        const mime = head(o.settings.filter(o => o.name === 'mime'));
                        const formula = head(o.settings.filter(o => o.name === 'formula'));
                        let mimeValue = '';

                        if (mime) {
                            mimeValue = mime.value.map(o => o.code ? o.code : o.ext);
                        }

                        const validations = Object.keys(o.validators).reduce((acc, cur) => {
                            if (o.validators[cur]) {
                                if (['min', 'max', 'minLength', 'maxLength', 'maxSize'].includes(cur)) {
                                    acc[cur] = parseInt(o.validators[cur]);
                                } else if ('pattern' === cur) {
                                    acc[cur] = new RegExp(o.validators[cur]);
                                } else if ('isRequired' === cur) {
                                    //acc[cur] = o.validators[cur];
                                    acc['required'] = true;
                                } else if ('custom' === cur && validationFns[o.validators[cur]]) {
                                    if (!acc.validate) {
                                        acc.validate = {};
                                    }
                                    acc.validate[o.validators[cur]] = validationFns[o.validators[cur]];
                                }
                            }

                            return acc;
                        }, {});
                        //console.log('validations', validations, o.name)

                        return ['paragraph'].includes(o.name) && text
                            ? <div key={o.id}>
                                <div className="ql-editor">
                                    {renderHtmlContent(text.value)}
                                </div>
                            </div>
                            : <FormField
                                key={o.id}
                                readOnly={formula && !isEmpty(formula.value)}
                                type={o.name}
                                disabled={o.name === 'fileupload' || 'DRAFT' !== applicationStatus}
                                fieldName={o.id}
                                label={label ? label.value : ''}
                                placeholder={placeholder ? placeholder.value : ''}
                                control={control}
                                register={register}
                                errors={errors}
                                defaultValue={values[o.id] ? values[o.id] : ''}
                                maxFractionDigits={step ? step.value : 0}
                                accept={mimeValue}
                                config={validations}
                                options={options ? options.value : []}
                                setDataFn={setValue}
                                saveFormCallback={saveDraft}
                                sourceId={getApplicationId()}
                                useGrouping={false}
                                tableColumns={tableColumns ? tableColumns.value : {}}
                            />
                    })}

                    <div className="appPage__spacer"></div>

                    <div className="appPageSection__hr">
                        <span>{__('Azioni rapide', 'gepafin')}</span>
                    </div>

                    <div className="appPageSection__preview">
                        {actionBtns}
                    </div>
                </form>
            </div>
        </div>
    )

}

export default BandoApplicationPreview;