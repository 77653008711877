import { head, isEmpty, isNil, sum } from 'ramda';
import getNumberFormatted from '../../../../../../../helpers/getNumberFormatted';

const LastRowCell = ({columnId, lastRowCfg, columnMeta = {}, getColumnDataFn}) => {
    const cellData = head(lastRowCfg.filter(o => !isNil(o[columnId])));
    let cellValue = cellData[columnId];

    if (columnMeta.enableFormula) {
        const getAllRowsValues = getColumnDataFn(columnId)
            .map(v => isEmpty(v) || isNil(v) ? 0 : v);

        if (cellValue === 'sum') {
            cellValue = getNumberFormatted(sum(getAllRowsValues));
        } else {
            cellValue = 0;
        }
    }

    return <td>{cellValue}</td>;
};

export default LastRowCell;