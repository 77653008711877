import React from 'react';
import { __ } from '@wordpress/i18n';
import { Tooltip, ResponsiveContainer, Cell, Pie, PieChart } from 'recharts';
import { isEmpty } from 'ramda';
import getBandoLabel from '../../helpers/getBandoLabel';

// components


const ChartStatoDomande = ({ title, data = [] }) => {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="chartCard__tooltip">
                    <p className="chartCard__tooltipTitle">{getBandoLabel(payload[0].name)}</p>
                    <p className="chartCard__tooltipText">
                        {__('Domande', 'gepafin')}: {payload[0].value}
                    </p>
                </div>
            );
        }
        return null;
    };

    return (<div className="chartCard">
        {title ? <span className="chartCard__title">{title}</span> : null}
        {data && !isEmpty(data)
            ? <div className="chartCard__chart">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="numberOfSubmitedApplications"
                            nameKey="status"
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </div> : null}
    </div>)
}

export default ChartStatoDomande;