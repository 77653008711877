import React, { useState, useEffect, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { classNames } from 'primereact/utils';

// store
import { storeSet } from '../../store';

// service
import FormsService from '../../service/forms-service';

// tools
import uniqid from '../../helpers/uniqid';
import set404FromErrorResponse from '../../helpers/set404FromErrorResponse';

// components
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import BandoService from '../../service/bando-service';
import { Toast } from 'primereact/toast';

const BandoForms = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [selectedForm, setSelectedForm] = useState(null);
    const [selectedForDuplicateForm, setSelectedForDuplicateForm] = useState(0);
    const [forms, setForms] = useState([]);
    const [bandoStatus, setBandoStatus] = useState('');
    const [allBandiOptions, setAllBandiOptions] = useState([]);
    const [allBandiIsLoading, setAllBandiIsLoading] = useState(false);
    const [selectedBandoIdForDuplicate, setSelectedBandoIdForDuplicate] = useState(0);
    const [allFormsOptions, setAllFormsOptions] = useState([]);
    const [allFormsIsLoading, setAllFormsIsLoading] = useState(false);
    const [selectedFormIdForDuplicate, setSelectedFormIdForDuplicate] = useState(0);
    const toast = useRef(null);

    const doCreateNewForm = () => {
        navigate(`/bandi/${id}/forms/new`);
    }

    const goToEditBando = () => {
        navigate(`/bandi/${id}`);
    }

    const openBandoFlowManagement = () => {
        navigate(`/bandi/${id}/flow`);
    }

    const goToEditForm = () => {
        if (selectedForm && selectedForm !== 0) {
            navigate(`/bandi/${id}/forms/${selectedForm}`);
        }
    }

    const doDuplicateForm = () => {
        const selectedFormArr = forms.filter(o => o.value === selectedForDuplicateForm);

        if (!isEmpty(selectedFormArr)) {
            storeSet.main.setAsyncRequest();
            FormsService.getFormById(selectedForDuplicateForm, getFormDuplicateCallback, errGetFormDuplicateCallbacks);
        }
    }

    const getFormDuplicateCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const newLabel = `${data.data.label} (copy)`;

            const formData = {
                label: newLabel,
                content: data.data.content.map(o => ({
                    ...o,
                    id: uniqid()
                }))
            }

            FormsService.createFormForCall(
                id,
                formData,
                formCreateCallback,
                errFormCreateCallback
            );
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetFormDuplicateCallbacks = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const formCreateCallback = (data) => {
        if (data.status === 'SUCCESS') {
            storeSet.main.unsetAsyncRequest();

            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: __('Il form è stato aggiornato correttamente!', 'gepafin')
                });
            }

            setTimeout(() => {
                navigate(`/bandi/${id}/forms/${data.data.id}`);
            }, 1000)
        }
    }

    const errFormCreateCallback = (data) => {
        storeSet.main.unsetAsyncRequest();
        if (toast.current) {
            toast.current.show({
                severity: 'error',
                summary: '',
                detail: data.message
            });
        }
    }

    /*const goToEditFormFromTemplate = () => {
        console.log('goToEditFormFromTemplate', selectedTemplate)
    }*/

    const getCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setBandoStatus(data.data.status);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getFormsCallback = (data) => {
        if (data.status === 'SUCCESS') {
            const forms = data.data.map(o => ({ label: o.label, value: o.id }))
            setForms(forms);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetFormsCallback = (data) => {
        set404FromErrorResponse(data);
        storeSet.main.unsetAsyncRequest();
    }

    const getAllBandiCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setAllBandiOptions(data.data.filter(o => o.id !== parseInt(id)).map(o => ({ label: o.name, value: o.id })))
        }
        setAllBandiIsLoading(false);
    }

    const errGetAllBandiCallback = () => {
        setAllBandiIsLoading(false);
    }

    const getAllFormsCallback = (data) => {
        if (data.status === 'SUCCESS') {
            setAllFormsOptions(data.data.map(o => ({ label: o.label, value: o.id })));
        }
        setAllFormsIsLoading(false);
    }

    const errGetAllFormsCallback = () => {
        setAllFormsIsLoading(false);
    }

    const doDuplicateFormOfAnotherBando = () => {
        if (selectedBandoIdForDuplicate !== 0 && selectedFormIdForDuplicate !== 0) {
            storeSet.main.setAsyncRequest();
            FormsService.getFormById(selectedFormIdForDuplicate, getFormDuplicateCallback, errGetFormDuplicateCallbacks);
        }
    }

    useEffect(() => {
        if (selectedBandoIdForDuplicate !== 0) {
            setAllFormsIsLoading(true);
            FormsService.getFormsForCall(selectedBandoIdForDuplicate, getAllFormsCallback, errGetAllFormsCallback);
        }
    }, [selectedBandoIdForDuplicate]);

    useEffect(() => {
        const parsed = parseInt(id)
        const bandoId = !isNaN(parsed) ? parsed : 0;

        storeSet.main.setAsyncRequest();
        BandoService.getBando(id, getCallback, errGetCallback);
        FormsService.getFormsForCall(bandoId, getFormsCallback, errGetFormsCallback);

        setAllBandiIsLoading(true);
        BandoService.getBandi(getAllBandiCallback, errGetAllBandiCallback);
    }, [id]);

    return (
        <div className="appPage">
            <div className="appPage__pageHeader">
                <h1>{__('Crea o modifica form per il Bando', 'gepafin')}</h1>
                <p>
                    {__('Scegli come vuoi procedere:', 'gepafin')}
                </p>
            </div>

            <div className="appPage__spacer"></div>
            <Toast ref={toast}/>

            <div className="appPage__content">

                {/*<div className="appPageSection__withBorder disabled">
                    <h2>{__('Usa un template', 'gepafin')}</h2>
                    <div className="row">
                        <p>{__('Scegli tra i template predefiniti e personalizzali', 'gepafin')}</p>
                        <Dropdown
                            id="template"
                            disabled={true}
                            value={selectedTemplate}
                            onChange={(e) => setSelectedTemplate(e.value)}
                            options={templates}
                            optionLabel="label"
                            placeholder={__('Seleziona template', 'gepafin')}/>
                        <Button
                            type="button"
                            outlined
                            disabled={true}
                            onClick={goToEditFormFromTemplate}
                            label={__('Crea', 'gepafin')}
                            icon="pi pi-plus" iconPos="right"/>
                    </div>
                </div>*/}

                <div className="appPageSection__withBorder">
                    <h2>{__('Crea un nuovo Form o duplica', 'gepafin')}</h2>
                    <div className="row">
                        <p>{__('Inizia con un form completamente vuoto e personalizzabile', 'gepafin')}</p>
                        <Button
                            type="button"
                            disabled={'PUBLISH' === bandoStatus}
                            onClick={doCreateNewForm}
                            label={__('Crea form', 'gepafin')}/>
                    </div>
                    <div className="row">
                        <p>{__('Duplica il form creato in precedenza', 'gepafin')}</p>
                        <div className="row">
                            <Dropdown
                                id="form"
                                disabled={isEmpty(forms)}
                                value={selectedForDuplicateForm}
                                onChange={(e) => setSelectedForDuplicateForm(e.value)}
                                options={forms}
                                optionLabel="label"
                                placeholder={__('Seleziona form', 'gepafin')}/>
                            <Button
                                type="button"
                                disabled={'PUBLISH' === bandoStatus || isEmpty(forms) || selectedForDuplicateForm === 0}
                                onClick={doDuplicateForm}
                                label={__('Duplicare', 'gepafin')}/>
                        </div>
                    </div>

                    <div className="row">
                        <p>{__('Duplica il form dal altro bando', 'gepafin')}</p>
                        <div className="row">
                            <Dropdown
                                id="form"
                                disabled={allBandiIsLoading}
                                value={selectedBandoIdForDuplicate}
                                onChange={(e) => setSelectedBandoIdForDuplicate(e.value)}
                                options={allBandiOptions}
                                placeholder={__('Seleziona bando', 'gepafin')}/>
                            <Dropdown
                                id="form"
                                disabled={allFormsIsLoading}
                                value={selectedFormIdForDuplicate}
                                onChange={(e) => setSelectedFormIdForDuplicate(e.value)}
                                options={allFormsOptions}
                                placeholder={__('Seleziona form', 'gepafin')}/>
                            <Button
                                type="button"
                                disabled={selectedBandoIdForDuplicate === 0 || selectedFormIdForDuplicate === 0}
                                onClick={doDuplicateFormOfAnotherBando}
                                label={__('Duplicare', 'gepafin')}/>
                        </div>
                    </div>
                </div>

                <div className={classNames(['appPageSection__withBorder', (isEmpty(forms) ? 'disabled' : '')])}>
                    <h2>{__('Modifica form esistente', 'gepafin')}</h2>
                    <div className="row">
                        <p>{__('Continua a lavorare su un form precedentemente salvato', 'gepafin')}</p>
                        <div className="row">
                            <Dropdown
                                id="form"
                                disabled={isEmpty(forms)}
                                value={selectedForm}
                                onChange={(e) => setSelectedForm(e.value)}
                                options={forms}
                                optionLabel="label"
                                placeholder={__('Seleziona form', 'gepafin')}/>
                            <Button
                                type="button"
                                outlined
                                disabled={!selectedForm}
                                onClick={goToEditForm}
                                label={__('Modifica', 'gepafin')}
                                icon="pi pi-cog" iconPos="right"/>
                        </div>
                    </div>
                </div>

                <div className="appPageSection">

                    <div className="row">
                        <Button
                            type="button"
                            outlined
                            onClick={goToEditBando}
                            label={__('Indietro', 'gepafin')}
                            icon="pi pi-arrow-left" iconPos="left"/>

                        <Button
                            type="button"
                            onClick={openBandoFlowManagement}
                            icon="pi pi-sitemap"
                            iconPos="right"
                            label={__('Gestisci flusso dei form', 'gepafin')}/>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BandoForms;