import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { klona } from 'klona';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// api
import EvaluationFormsService from '../../../../service/evaluation-forms-service';
import FormsService from '../../../../service/forms-service';

// store
import { storeGet, storeSet } from '../../../../store';

// tools
import set404FromErrorResponse from '../../../../helpers/set404FromErrorResponse';

// components
import BandoEditFormActions from '../BandoEditFormActions';
import { Toast } from 'primereact/toast';
import FormBuilder from '../../../BandoFormsEdit/components/FormBuilder';
//import { elementItems } from '../../../../tempData';


const BandoEditFormStep3 = forwardRef(function () {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formName, setFormName] = useState('');
    const [bandoStatus, setBandoStatus] = useState('');
    const toast = useRef(null);

    const getBandoId = () => {
        const parsed = parseInt(id)
        return !isNaN(parsed) ? parsed : 0;
    }

    const onSaveDraft = () => {
        const content = storeGet.main.formElements();
        const formId = storeGet.main.formId();
        const formData = {
            label: formName,
            content
        }

        storeSet.main.setAsyncRequest();
        EvaluationFormsService.updateForm(formId, formData, updateFormCallback, errUpdateFormCallback)
    }

    const updateFormCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            setBandoStatus(resp.data.callStatus);
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: '',
                    detail: __('Il bando è stato aggiornato correttamente!', 'gepafin')
                });
            }
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errUpdateFormCallback = (resp) => {
        set404FromErrorResponse(resp);
        storeSet.main.unsetAsyncRequest();
    }

    const openPreview = () => {
        const bandoId = getBandoId();
        navigate(`/bandi/${bandoId}/preview`);
    }

    const openPreviewEvaluation = () => {
        const bandoId = getBandoId();
        navigate(`/bandi/${bandoId}/preview-evaluation`);
    }

    const getElementItemsCallback = (data) => {
        if (data.status === 'SUCCESS') {
            //storeSet.main.elementItems(elementItems.sort((a, b) => a.sortOrder - b.sortOrder));
            storeSet.main.elementItems(
                data.data
                    .filter(o => !['fileselect'].includes(o.name))
                    .sort((a, b) => a.sortOrder - b.sortOrder)
            );
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetElementItemsCallbacks = (data) => {
        storeSet.main.unsetAsyncRequest();
    }

    const getFormCallback = (resp) => {
        if (resp.status === 'SUCCESS') {
            storeSet.main.formId(resp.data.id);
            storeSet.main.formLabel(resp.data.label);
            setFormName(resp.data.label);
            setBandoStatus(resp.data.callStatus);
            const elements = klona(resp.data.content);
            storeSet.main.formElements(elements);
        }
        storeSet.main.unsetAsyncRequest();
    }

    const errGetFormCallback = (resp) => {
        set404FromErrorResponse(resp);
        storeSet.main.unsetAsyncRequest();
    }

    useEffect(() => {
        storeSet.main.setAsyncRequest();
        EvaluationFormsService.getFormForCall(id, getFormCallback, errGetFormCallback)
    }, [id]);

    useEffect(() => {
        storeSet.main.setAsyncRequest();
        FormsService.getElementItems(getElementItemsCallback, errGetElementItemsCallbacks);

        return () => {
            storeSet.main.formId(0);
            storeSet.main.formElements([]);
            storeSet.main.activeElement('');
            storeSet.main.selectedElement('');
        }
    }, []);

    return (
        <div className="appForm">
            <div className="appPageSection">
                <DndProvider backend={HTML5Backend}>
                    <FormBuilder callStatus={bandoStatus} context="call"/>
                </DndProvider>
            </div>

            <div className="appPage__spacer"></div>

            <div className="appPageSection__hr">
                <span>{__('Azioni', 'gepafin')}</span>
            </div>

            <Toast ref={toast} />
            <BandoEditFormActions
                id={id}
                status={bandoStatus}
                submitFn={onSaveDraft}
                openPreview={openPreview}
                openPreviewEvaluation={openPreviewEvaluation}/>
        </div>
    )
})

export default BandoEditFormStep3;