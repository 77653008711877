import React, { useEffect, useState } from 'react';
import { __ } from '@wordpress/i18n';
import { Tooltip, ResponsiveContainer, Cell, Pie, PieChart, Legend } from 'recharts';
import { isEmpty } from 'ramda';

// tools
import getBandoLabel from '../../helpers/getBandoLabel';


const ChartDomandePerStato = ({ title, data = [] }) => {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];
    const [chartData, setChartData] = useState({});

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="chartCard__tooltip">
                    <p className="chartCard__tooltipTitle">{getBandoLabel(payload[0].name)}</p>
                    <p className="chartCard__tooltipText">
                        {payload[0].name}: {payload[0].value}
                    </p>
                </div>
            );
        }
        return null;
    };

    useEffect(() => {
        const grouped = data.reduce((acc, cur) => {
            if (cur.status === 'APPROVED') {
                acc.approved.value = cur.numberOfApplication;
            } else if (cur.status === 'REJECTED') {
                acc.rejected.value = cur.numberOfApplication;
            } else {
                acc.inProgress.value += cur.numberOfApplication;
            }
            return acc;
        }, {
            inProgress: {value: 0, label: __('In corso', 'gepafin')},
            approved: {value: 0, label: __('Approvato', 'gepafin')},
            rejected: {value: 0, label: __('Respinto', 'gepafin')}
        });
        setChartData(grouped)
    }, [data]);

    return (<div className="chartCard">
        {title ? <span className="chartCard__title">{title}</span> : null}
        {chartData && !isEmpty(chartData)
            ? <div className="chartCard__chart">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={Object.values(chartData)}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            nameKey="label"
                        >
                            {Object.values(chartData).map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                        <Legend verticalAlign="top" height={36}/>
                    </PieChart>
                </ResponsiveContainer>
            </div> : null}
    </div>)
}

export default ChartDomandePerStato;