export const elementItems = [
    {
        id: 1,
        sortOrder: 1,
        name: 'textinput',
        label: 'Testo Breve',
        description: 'Per risposte concise (nomi, titoli, brevi descrizioni)',
        settings: [
            {
                name: "label",
                value: "Testo Breve"
            },
            {
                name: "placeholder",
                value: ""
            }
        ],
        validators: {
            isRequired: false,
            minLength: null,
            maxLength: null,
            pattern: null,
            custom: null
        }
    },
    {
        id: 2,
        sortOrder: 2,
        name: 'textarea',
        label: 'Testo Lungo',
        description: 'Campo di testo esteso per paragrafi, descrizioni, proposte',
        settings: [
            {
                name: "label",
                value: "Testo Lungo"
            },
            {
                name: "placeholder",
                value: ""
            }
        ],
        validators: {
            isRequired: false,
            minLength: null,
            maxLength: null,
            pattern: null,
            custom: null
        }
    },
    {
        id: 3,
        sortOrder: 3,
        name: 'wysiwyg',
        label: 'Campo di Testo Formattato',
        description: 'Editor avanzato per testo con formattazione',
        settings: [
            {
                name: "label",
                value: "Testo Formattato"
            },
            {
                name: "placeholder",
                value: ""
            }
        ],
        validators: {
            isRequired: false,
            minLength: null,
            maxLength: null,
            custom: null
        }
    },
    {
        id: 4,
        sortOrder: 4,
        name: 'numberinput',
        label: 'Campo Numerico',
        description: "Per l'inserimento di valori numerici (quantità, importi, percentuali)",
        settings: [
            {
                name: "label",
                value: "Numero"
            },
            {
                name: "placeholder",
                value: 0
            },
            {
                name: "step",
                value: 0
            },
            {
                name: "isRequestedAmount",
                value: false
            },
            {
                name: "variable",
                value: []
            },
            {
                name: "formula",
                value: ""
            },
        ],
        validators: {
            isRequired: false,
            min: null,
            max: null,
            pattern: null,
            custom: null
        }
    },
    {
        id: 5,
        sortOrder: 5,
        name: 'radio',
        label: 'Scelta Singola',
        description: 'Gruppo di opzioni per selezione singola',
        settings: [
            {
                name: "label",
                value: "Scelta Singola"
            },
            {
                name: "options",
                value: []
            }
        ],
        validators: {
            isRequired: false,
            custom: null
        }
    },
    {
        id: 6,
        sortOrder: 6,
        name: 'select',
        label: 'Menu a Tendina',
        description: 'Selezione da opzioni predefinite',
        settings: [
            {
                name: "label",
                value: "Menu a Tendina"
            },
            {
                name: "options",
                value: []
            }
        ],
        validators: {
            isRequired: false,
            custom: null
        }
    },
    {
        id: 7,
        sortOrder: 7,
        name: 'checkboxes',
        label: 'Scelta Multipla',
        description: 'Gruppo di opzioni per selezione singola o multipla',
        settings: [
            {
                name: "label",
                value: "Scelta Multipla"
            },
            {
                name: "options",
                value: []
            }
        ],
        validators: {
            isRequired: false,
            min: null,
            max: null,
            custom: null
        }
    },
    {
        id: 8,
        sortOrder: 8,
        name: 'switch',
        label: 'Casella di Spunta',
        description: 'Per selezioni binarie, accettazioni, conferme',
        settings: [
            {
                name: "label",
                value: "Casella di Spunta"
            },
            {
                name: "isChecklistItem",
                value: false
            }
        ],
        validators: {
            isRequired: false
        }
    },
    {
        id: 9,
        sortOrder: 9,
        name: 'datepicker',
        label: 'Data',
        description: 'Selezione di data',
        settings: [
            {
                name: "label",
                value: "Data"
            }
        ],
        validators: {
            isRequired: false,
            custom: null
        }
    },
    {
        id: 10,
        sortOrder: 10,
        name: 'fileupload',
        label: 'Caricamento File',
        description: "Per l'upload di documenti o immagini",
        settings: [
            {
                name: "label",
                value: "Caricamento File"
            },
            {
                name: "mime",
                value: []
            },
            {
                name: "isDelegation",
                value: false
            }
        ],
        validators: {
            isRequired: false,
            maxSize: 100000,
            custom: null
        }
    },
    {
        id: 11,
        sortOrder: 11,
        name: 'textinput',
        label: 'Campo Partita IVA',
        description: "Specifico per l'inserimento del numero di Partita IVA",
        settings: [
            {
                name: "label",
                value: "Partita IVA"
            },
            {
                name: "placeholder",
                value: ""
            }
        ],
        validators: {
            isRequired: true,
            custom: 'isPIVA'
        }
    },
    {
        id: 12,
        sortOrder: 12,
        name: 'textinput',
        label: 'Campo Codice Fiscale',
        description: "Specifico per l'inserimento del Codice Fiscale italiano per persone fisiche e giuridiche",
        settings: [
            {
                name: "label",
                value: "Codice Fiscale"
            },
            {
                name: "placeholder",
                value: ""
            }
        ],
        validators: {
            isRequired: true,
            custom: 'isCodiceFiscale'
        }
    },
    {
        id: 13,
        sortOrder: 13,
        name: 'textinput',
        label: 'Campo CAP',
        description: "Per l'inserimento del Codice di Avviamento Postale",
        settings: [
            {
                name: "label",
                value: "CAP"
            },
            {
                name: "placeholder",
                value: ""
            }
        ],
        validators: {
            isRequired: true,
            custom: 'isCAP'
        }
    },
    {
        id: 14,
        sortOrder: 14,
        name: 'textinput',
        label: 'Campo IBAN',
        description: "Per l'inserimento del codice IBAN",
        settings: [
            {
                name: "label",
                value: "IBAN"
            },
            {
                name: "placeholder",
                value: ""
            }
        ],
        validators: {
            isRequired: true,
            custom: 'isIBAN'
        }
    },
    {
        id: 15,
        sortOrder: 15,
        name: 'textinput',
        label: 'Campo Email',
        description: "Per l'inserimento di indirizzi email standard (non PEC)",
        settings: [
            {
                name: "label",
                value: "Campo Email"
            },
            {
                name: "placeholder",
                value: "nome@esempio.it"
            }
        ],
        validators: {
            isRequired: false,
            custom: 'isEmail'
        }
    },
    {
        id: 16,
        sortOrder: 16,
        name: 'textinput',
        label: 'Campo PEC',
        description: "Specifico per l'inserimento di un indirizzo di Posta Elettronica Certificata",
        settings: [
            {
                name: "label",
                value: "Campo PEC"
            },
            {
                name: "placeholder",
                value: "nome@pec.it"
            }
        ],
        validators: {
            isRequired: false,
            custom: 'isEmailPEC'
        }
    },
    {
        id: 17,
        sortOrder: 17,
        name: 'textinput',
        label: 'Campo URL',
        description: "Per l'inserimento di indirizzi web",
        settings: [
            {
                name: "label",
                value: "Indirizzo URL"
            },
            {
                name: "placeholder",
                value: ""
            }
        ],
        validators: {
            isRequired: false,
            custom: 'isUrl'
        }
    },
    {
        id: 18,
        sortOrder: 18,
        name: 'textinput',
        label: 'Marca da bollo',
        description: 'Per inserire codice di marca da bollo',
        settings: [
            {
                name: "label",
                value: "Marca da bollo"
            },
            {
                name: "placeholder",
                value: "Numero identificativo"
            }
        ],
        validators: {
            isRequired: false,
            custom: 'isMarcaDaBollo'
        }
    },
    {
        id: 19,
        sortOrder: 19,
        name: 'paragraph',
        label: 'Paragrafo',
        description: 'Semplice testo formattato',
        settings: [
            {
                name: "text",
                value: ""
            }
        ],
        validators: {}
    },
    {
        id: 20,
        sortOrder: 20,
        name: 'table',
        label: 'Tabella',
        description: 'Tabella',
        settings: [
            {
                name: "label",
                value: "Tabella"
            },
            {
                name: "table_columns",
                value: {}
            }
        ],
        validators: {
            custom: 'nonEmptyTables'
        }
    },
    {
        id: 21,
        sortOrder: 21,
        name: 'criteria_table',
        label: 'Tabella di criteri',
        description: 'Tabella di criteri',
        settings: [
            {
                name: "label",
                value: "Tabella"
            },
            {
                name: "criteria_table_columns",
                value: {}
            },
            {
                name: "variable",
                value: []
            }
        ],
        validators: {
            custom: 'nonEmptyTables'
        }
    },
    {
        id: 22,
        sortOrder: 22,
        name: 'switch',
        label: 'Casella di spunta "Checklist"',
        description: 'Per selezioni binarie, accettazioni, conferme',
        settings: [
            {
                name: "label",
                value: "Casella di Spunta"
            },
            {
                name: "isChecklistItem",
                value: true
            }
        ],
        validators: {
            isRequired: false
        }
    },
    {
        id: 23,
        sortOrder: 23,
        name: 'fileselect',
        label: 'Seleziona File',
        description: "Per selezionare di documenti o immagini",
        settings: [
            {
                name: "label",
                value: "Seleziona File"
            },
            {
                name: "documentCategories",
                value: []
            },
            {
                name: "isDelegation",
                value: false
            }
        ],
        validators: {
            isRequired: false
        }
    }
]
