import { head } from 'ramda';

export const actionsAlpha = (set, get, api) => ({
    setAsyncRequest:        () => {
        const num = get.isAsyncRequest();
        set.isAsyncRequest(num + 1);
    },
    unsetAsyncRequest:      () => {
        const num = get.isAsyncRequest();
        set.isAsyncRequest(num - 1 < 0 ? 0 : num - 1);
    },
})

export const actionsBeta = (set, get, api) => ({
    setAuthData: ({ userData, token }) => {
        set.userData(userData);
        set.token(token);
    },
    doLogout: () => {
        set.userData({});
        set.token('');
    },
    removeElement: (id) => {
        const elements = get.formElements();
        const newElements = elements.filter(o => o.id !== id);
        set.formElements(newElements);
    },
    moveElement: (dragIndex, hoverIndex, item) => {
        const prevFields = get.formElements();

        if (dragIndex === -1) {
            const configs = get.elementItems();
            const itemCfg = head(configs.filter(o => o.id === item.dbId));
            const newItem = {
                ...itemCfg,
                id: item.id,
                dbId: item.dbId
            }
            const newElements = prevFields.toSpliced(hoverIndex, 0, newItem);
            set.formElements(newElements);
        } else {
            let newFields = prevFields.toSpliced(dragIndex, 1);
            const newElements = newFields.toSpliced(hoverIndex, 0, prevFields[dragIndex]);
            set.formElements(newElements);
        }
    }
});
