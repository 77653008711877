import { NetworkService } from './network-service';

const API_BASE_URL = process.env.REACT_APP_API_EXECUTION_ADDRESS;

export default class ApplicationService {

    static getApplications = (callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/application`, callback, errCallback, queryParams);
    };

    static getApplicationsPaginated = (body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/application/pagination`, body, callback, errCallback, queryParams);
    };

    static getApplication = (id, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/application/${id}`, callback, errCallback);
    };

    static getApplicationForm = (id, callback, errCallback, queryParams) => {
        NetworkService.get(`${API_BASE_URL}/application/${id}/form/next-previous`, callback, errCallback, queryParams);
    };

    static createApplication = (id, body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/application/call/${id}`, body, callback, errCallback, queryParams);
    };

    static saveDraft = (id, body, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/application/${id}`, body, callback, errCallback, queryParams);
    };

    static updateStatusApplication = (id, body, callback, errCallback, queryParams) => {
        NetworkService.put(`${API_BASE_URL}/application/${id}/status`, body, callback, errCallback, queryParams);
    };

    static validateApplication = (id, body, callback, errCallback, queryParams) => {
        NetworkService.post(`${API_BASE_URL}/application/${id}/validate`, body, callback, errCallback, queryParams);
    };

    static downloadApplicationPdf = (id, body, callback, errCallback, queryParams) => {
        NetworkService.postBlob(`${API_BASE_URL}/application/${id}/download-pdf`, body, callback, errCallback, queryParams);
    };

    static getApplicationSignedPdf = (id, callback, errCallback) => {
        NetworkService.get(`${API_BASE_URL}/application/${id}/signedDocument`, callback, errCallback);
    };

    static uploadApplicationSignedPdf = (id, body, callback, errCallback, queryParams) => {
        NetworkService.postMultiPart(`${API_BASE_URL}/application/${id}/signedDocument/upload`, body, callback, errCallback, queryParams);
    };

    static deleteApplicationSignedPdf = (id, callback, errCallback) => {
        NetworkService.delete(`${API_BASE_URL}/application/${id}/signedDocument`, {}, callback, errCallback);
    };

    static deleteApplication = (id, callback, errCallback) => {
        NetworkService.delete(`${API_BASE_URL}/application/${id}`, {}, callback, errCallback);
    };

    static downloadCompleteZip = (id, callback, errCallback, queryParams) => {
        NetworkService.getBlob(`${API_BASE_URL}/application/${id}/documents/zip`, callback, errCallback, queryParams);
    };
}
