import React from 'react';
import { classNames } from 'primereact/utils';
import { isNil } from 'ramda';

// components
import { Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';

const DatepickerRange = ({
                             fieldName,
                             label,
                             control,
                             errors,
                             defaultValue = [new Date()],
                             config = {},
                             infoText = null,
                             minDate = null,
                             maxDate = null,
                             disabled = false
                         }) => {
    const datesDefaultValue = !isNil(defaultValue) && defaultValue.length
        ? defaultValue.map(v => new Date(v))
        : [];
    return (
        <>
            <label htmlFor={fieldName} className={classNames({ 'p-error': errors[fieldName] })}>
                {label}{config.required ? '*' : null}
            </label>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={datesDefaultValue}
                rules={config}
                render={({ field, fieldState }) => (
                    <Calendar id={field.name}
                              disabled={disabled}
                              value={field.value}
                              onChange={field.onChange}
                              dateFormat="dd/mm/yy"
                              mask="99/99/9999"
                              showIcon
                              minDate={minDate}
                              maxDate={maxDate}
                              selectionMode="range"
                              readOnlyInput
                              hideOnRangeSelection
                              showButtonBar
                              className={classNames({ 'p-invalid': fieldState.invalid })}/>
                )}/>
            {infoText ? <small>{infoText}</small> : null}
        </>)
}

export default DatepickerRange;